<template>
  <div :class="{'payment-requests': !user.adminDetails.vendor}">
    <v-container class="typeform-selector d-flex" v-if="!showInvitationMessage || !showInvitationVendor">
      <v-btn
        v-if="!user.adminDetails.vendor"
        height="56px"
        color="primary"
        class="ml-4"
        @click="handleInvitationWindow('reimbursement')"
      >
        Reimbursement
      </v-btn>
      <v-btn
        v-if="user.user.userRole !== 'STAFF' || user.adminDetails.vendor"
        height="56px"
        color="primary"
        class="ml-4"
        @click="handleInvitationWindow('vendor')"
      >
        SUBMIT YOUR INVOICE
      </v-btn>
    </v-container>
    <v-card :style="{ opacity: showTypeform ? '100%' : '0' }" v-if="!showInvitationMessage && !showInvitationVendor">
      <div ref="typeform__embed" class="typeform__embed"></div>
      <v-snackbar
        v-model="typeformProcessing"
        min-width="fit-content"
        elevation="12"
        timeout="-1"
        color="warning"
        rounded="pill"
        content-class="text-center"
        bottom
      >
        <v-icon left>mdi-timer-sand</v-icon>Processing your answers...
      </v-snackbar>
    </v-card>
    <div v-if="showInvitationMessage" class="invitation">
      <p class="invitation__title">Hi, {{ user.user.firstName }} {{ user.user.lastName }}!</p>
      <p class="invitation__subtitle">Welcome to the Co. Cue Reimbursement Form.</p>
      <p class="invitation__subtitle">Please make sure to provide the reimbursement amount in USD ($).</p>
      <div class="mt-10">
        <v-btn
          class="invitation__btn ma-2"
          x-large
          @click="requestReimbursement"
        >
          Start
        </v-btn>
      </div>
    </div>
    <div v-if="showInvitationVendor" class="invitation">
      <p class="invitation__title">Hi, {{ user.user.firstName }} {{ user.user.lastName }}!</p>
      <p class="invitation__subtitle">Welcome to the Co. Cue Payment Request.</p>
      <p class="invitation__subtitle">Please make sure to provide the invoice amount in USD ($).</p>
      <div class="mt-10">
        <v-btn
          class="invitation__btn ma-2"
          x-large
          @click="requestVendor"
        >
          Start
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
// vuex
import { mapGetters, mapActions } from "vuex";
// libraries
import * as typeformEmbed from "@typeform/embed";
// internal
import {
  processAssignment,
  queryTypeformData,
  getCustomerPriceList,
  getAllProjects,
  getAllStaff,
  getLimitedStaffData
} from "@/utils/newDbUtils";
import { publishMessage } from "@/utils/slackUtils";
import { BLANK_NEW_ASSIGNMENT_DATAS } from "@/utils/assignmentUtils";

export default {
  name: "PaymentRequests",
  data() {
    return {
      showInvitationMessage: false,
      showInvitationVendor: false,
      showTypeform: false,
      staff: [],
      typeformProcessing: false,
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  methods: {
    ...mapActions("flashMessage", ["handleFlash"]),
    handleInvitationWindow(type) {
      if (type === "vendor") {
        this.showInvitationVendor = true;
      } else {
        this.showInvitationMessage = true;
      }
    },
    requestReimbursement() {
      this.$router.push({ name: 'Reimbursement Request Form' });
      this.showInvitationMessage = false;
    },
    requestVendor() {
      this.$router.push({ name: 'Vendor Invoice Form' });
      this.showInvitationVendor = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.payment-requests {
  max-width: 1400px;
  margin: 0 auto;
}
.container {
  margin-left: 0 !important;
}
.typeform {
  &__embed {
    height: 900px;
  }
}
.invitation {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
  &__title {
    font-size: 30px;
    font-weight: 400;
    line-height: 41.41px;
  }
  &__subtitle {
    font-size: 30px;
    font-weight: 400;
    line-height: 41.41px;
  }
  & button {
    background-color: #1976D2 !important;
    border-radius: 4px !important;
    color: #fff;
  }
}
</style>