import { BLANK_NEW_ASSIGNMENT_DATAS, BLANK_DUB_EDITOR_DATAS } from "@/utils/assignmentUtils";

export const assignmentModule = {
  state: () => ({
    // modals
    newAssignmentModalOpen: false,
    hoursSubmissionModalOpen: false,
    dubEditorModalOpen: false,
    // assignments
    newAssignmentDatas: BLANK_NEW_ASSIGNMENT_DATAS,
    dubEditorDatas: BLANK_DUB_EDITOR_DATAS,
    geoOptions: [],
    mediaTypeOptions: [],
    requestTypeOptions: [],
    statusOptions: [],
    projectsList: [],
    driList: [],
    assignmentsDeepClone: [],
    bulkEditing: false,
    bulkQueryEdit: false,
    updateBatchConfirmation: false,
    updatePPCheckModal: false,
    updatePPCheckValue: null,
    jumpOverPPCheck: true,
    hoursSubmissionAssignmentInfo: null,
    staffHoursData: [],
    staffReview: false
  }),
  mutations: {
    SET_NEW_ASSIGNMENT_MODAL_OPEN(state, assignmentModalOpen) {
      state.newAssignmentModalOpen = assignmentModalOpen;
    },
    SET_HOURS_SUBMISSION_MODAL_OPEN(state, hoursSubmissionModalOpen) {
      state.hoursSubmissionModalOpen = hoursSubmissionModalOpen;
    },
    SET_NEW_ASSIGNMENT_DATAS(state, newDatas) {
      state.newAssignmentDatas = newDatas;
    },
    SET_DUB_EDITOR_MODAL_OPEN(state, dubEditorModalOpen) {
      state.dubEditorModalOpen = dubEditorModalOpen;
    },
    SET_DUB_EDITOR_DATAS(state, dubEditorDatas) {
      state.dubEditorDatas = dubEditorDatas;
    },
    RESET_NEW_ASSIGNMENT_DATAS(state) {
      state.newAssignmentDatas = [...BLANK_NEW_ASSIGNMENT_DATAS];
    },
    DELETE_NEW_ASSIGNMENT_DATAS(state, datasIndex) {
      state.newAssignmentDatas.splice(datasIndex, 1);
    },
    SET_ASSIGNMENTS_DEEP_CLONE(state, assignmentsDeepClone) {
      state.assignmentsDeepClone = assignmentsDeepClone;
    },
    SET_COMMON_DATA(state, { field, data }) {
      state[field] = [...data];
    },
    SET_BULK_EDITING(state, bulkEditing) {
      state.bulkEditing = bulkEditing;
    },
    SET_BULK_QUERY_EDIT(state, bulkQueryEdit) {
      state.bulkQueryEdit = bulkQueryEdit;
    },
    SET_UPDATE_BATCH_CONFIRMATION(state, updateBatchConfirmation) {
      state.updateBatchConfirmation = updateBatchConfirmation;
    },
    SET_UPDATE_PP_CHECK_MODAL(state, updatePPCheckModal) {
      state.updatePPCheckModal = updatePPCheckModal;
    },
    SET_JUMP_OVER_PP_CHECK(state, jumpOverPPCheck) {
      state.jumpOverPPCheck = jumpOverPPCheck;
    },
    SET_UPDATE_PP_CHECK_VALUE(state, updatePPCheckValue) {
      state.updatePPCheckValue = updatePPCheckValue;
    },
    SET_HOURS_SUBMISSION_ASSIGNMENT_INFO(state, hoursSubmissionAssignmentInfo) {
      state.hoursSubmissionAssignmentInfo = hoursSubmissionAssignmentInfo;
    },
    SET_STAFF_HOURS_DATA(state, staffHoursData) {
      state.staffHoursData = staffHoursData;
    },
    SET_STAFF_REVIEW(state, staffReview) {
      state.staffReview = staffReview;
    },
  },
  actions: {
    setNewAssignmentModalOpen(context, assignmentModalOpen) {
      if (!assignmentModalOpen) {
        context.commit("RESET_NEW_ASSIGNMENT_DATAS");
        context.commit("SET_BULK_EDITING", false);
      }
      context.commit("SET_NEW_ASSIGNMENT_MODAL_OPEN", assignmentModalOpen);
    },
    setHoursSubmissionModalOpen(context, data) {
      if (data.assignmentInfo) context.commit("SET_HOURS_SUBMISSION_ASSIGNMENT_INFO", data.assignmentInfo);
      context.commit("SET_HOURS_SUBMISSION_MODAL_OPEN", data.status);
    },
    setDubEditorModalOpen(context, dubEditorModalOpen) {
      context.commit("SET_DUB_EDITOR_MODAL_OPEN", dubEditorModalOpen);
    },
    setNewAssignmentDatas(context, newDatas) {
      context.commit("SET_NEW_ASSIGNMENT_DATAS", newDatas);
    },
    setDubEditorDatas(context, dubEditorDatas) {
      context.commit("SET_DUB_EDITOR_DATAS", dubEditorDatas);
    },
    setAssignmentsDeepClone(context, assignmentsDeepClone) {
      context.commit("SET_ASSIGNMENTS_DEEP_CLONE", assignmentsDeepClone);
    },
    deleteNewAssignmentDatas(context, datasIndex) {
      context.commit("DELETE_NEW_ASSIGNMENT_DATAS", datasIndex);
    },
    setCommonData(context, { field, data }) {
      context.commit("SET_COMMON_DATA", { field, data });
    },
    setBulkEditing(context, bulkEditing) {
      context.commit("SET_BULK_EDITING", bulkEditing);
    },
    setBulkQueryEdit(context, bulkQueryEdit) {
      context.commit("SET_BULK_QUERY_EDIT", bulkQueryEdit);
    },
    setUpdateBatchConfirmation(context, updateBatchConfirmation) {
      context.commit("SET_UPDATE_BATCH_CONFIRMATION", updateBatchConfirmation);
    },
    setUpdatePPCheckModal(context, updatePPCheckModal) {
      context.commit("SET_UPDATE_PP_CHECK_MODAL", updatePPCheckModal);
    },
    setJumpOverPPCheck(context, jumpOverPPCheck) {
      context.commit("SET_JUMP_OVER_PP_CHECK", jumpOverPPCheck);
    },
    setUpdatePPCheckValue(context, updatePPCheckValue) {
      context.commit("SET_UPDATE_PP_CHECK_VALUE", updatePPCheckValue);
    },
    setStaffHoursData(context, staffHoursData) {
      context.commit("SET_STAFF_HOURS_DATA", staffHoursData);
    },
    openReviewModal(context, review) {
      context.commit("SET_STAFF_REVIEW", review);
    }
  },
  getters: {
    newAssignmentModalOpen: (state) => {
      return state.newAssignmentModalOpen;
    },
    hoursSubmissionModalOpen: (state) => {
      return state.hoursSubmissionModalOpen;
    },
    dubEditorModalOpen: (state) => {
      return state.dubEditorModalOpen;
    },
    newAssignmentDatas: (state) => {
      return state.newAssignmentDatas;
    },
    dubEditorDatas: (state) => {
      return state.dubEditorDatas;
    },
    geoOptions: (state) => {
      return state.geoOptions;
    },
    mediaTypeOptions: (state) => {
      return state.mediaTypeOptions;
    },
    requestTypeOptions: (state) => {
      return state.requestTypeOptions;
    },
    statusOptions: (state) => {
      return state.statusOptions;
    },
    projectsList: (state) => {
      return state.projectsList;
    },
    driList: (state) => {
      return state.driList;
    },
    isEditMode: (state) => {
      return state.newAssignmentDatas[0].hasOwnProperty("id");
    },
    assignmentsDeepClone: (state) => {
      return state.assignmentsDeepClone;
    },
    bulkEditing: (state) => {
      return state.bulkEditing;
    },
    bulkQueryEdit: (state) => {
      return state.bulkQueryEdit;
    },
    updateBatchConfirmation: (state) => {
      return state.updateBatchConfirmation;
    },
    updatePPCheckModal: (state) => {
      return state.updatePPCheckModal;
    },
    jumpOverPPCheck: (state) => {
      return state.jumpOverPPCheck;
    },
    updatePPCheckValue: (state) => {
      return state.updatePPCheckValue;
    },
    hoursSubmissionAssignmentInfo: (state) => {
      return state.hoursSubmissionAssignmentInfo;
    },
    staffHoursData: (state) => {
      return state.staffHoursData;
    },
    staffReviewStatus: (state) => {
      return state.staffReview;
    }
  },
};
