<template>
  <v-card class="d-flex flex-column hours-submission">
    <div class="hours-submission__container">
      <div class="d-flex align-center hours-submission__container--title">
        <v-icon color="#000">mdi-clock-outline</v-icon>
        <h6 class="ml-4">Log Work Time</h6>
      </div>
      <div class="d-flex flex-column hours-list">
        <div class="d-flex hours-list__item mt-8" 
          v-for="(workedHoursItem, index) in workTime" :key="index">
          <div class="hours-list__item-main"
            :class="{ 'w-100': staffReviewStatus }">
            <v-row class="mt-1">
              <v-col cols="12" sm="4" class="py-0">
                <v-menu
                  v-model="workedHoursItem.logDateMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="formatPickerDate(workedHoursItem.date)"
                      label="Date"
                      prepend-inner-icon="mdi-calendar"
                      outlined
                      readonly
                      hide-details
                      :readonly="staffReviewStatus"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    @input="workedHoursItem.logDateMenu = false"
                    v-model="workedHoursItem.date"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="4" class="py-0">
                <v-text-field
                  outlined
                  label="Worked Hours"
                  type="number"
                  :readonly="staffReviewStatus"
                  hide-details
                  :min="0" 
                  :max="24" 
                  :value="workedHoursItem.workedHours"
                  :rules="[hoursRule]"
                  @input="handleHoursInput(index, $event)"
                />
              </v-col>
              <v-col cols="12" sm="4" class="py-0">
                <v-select
                  outlined 
                  label="Worked Minutes" 
                  hide-details 
                  :items="workedMinutesList" 
                  :readonly="staffReviewStatus"
                  v-model="workedHoursItem.workedMinutes" 
                  :rules="[minutesRule]" 
                  @change="validateMinutes(workedHoursItem)" 
                  :min="0" 
                  :max="60" 
                  @blur="validateMinutes(workedHoursItem)" 
                ></v-select>
              </v-col>
            </v-row>
            <v-row class="mt-20px">
              <v-col cols="12" class="pb-0 pt-0">
                <v-text-field
                  outlined
                  rows="1"
                  hide-details
                  :readonly="staffReviewStatus"
                  label="Note"
                  v-model="workedHoursItem.note"
                />
              </v-col>
            </v-row>
          </div>
          <div v-if="!staffReviewStatus"
            class="d-flex align-center justify-center hours-list__item-remove">
            <v-btn
              fab
              small
              text
              @click="removeHoursItem(index)"
            >
              <v-icon color="#757575">
                mdi-delete
              </v-icon>
            </v-btn>
          </div>
        </div>
        <div class="d-flex align-center justify-space-between hours-list__total">
          <div class="d-flex align-center hours-list__total--time"
            v-if="!staffHoursData.length">
            <p class="mb-0 mt-1 hours-list__total--time-text">Total:</p>
            <p class="mb-0 ml-2 hours-list__total--time-chip">{{ totalWorkedTime }}</p>
            <p class="mb-0 mt-1 ml-2 hours-list__total--time-text2" v-if="workTime.length && workTime[0].date">for</p>
            <p class="mb-0 ml-2 hours-list__total--time-chip" v-if="workTime.length && workTime[0].date">{{ startDate }} - {{ endDate }}</p>
          </div>
          <div v-else>
            <div class="mb-2 d-flex align-center hours-list__total--time">
              <p class="mb-0 mt-1 hours-list__total--time-text">Original Total:</p>
              <p class="mb-0 ml-2 hours-list__total--time-chip">{{ totalStartWorkTime }}</p>
              <p class="mb-0 mt-1 ml-2 hours-list__total--time-text2" v-if="workTime.length && workTime[0].date">for</p>
              <p class="mb-0 ml-2 hours-list__total--time-chip" v-if="workTime.length && workTime[0].date">{{ startWorkTimeStartDate }} - {{ startWorkTimeEndDate }}</p>
            </div>
            <div class="d-flex align-center hours-list__total--time"
              v-if="!staffReviewStatus">
              <p class="mb-0 mt-1 hours-list__total--time-text">Resubmission Total:</p>
              <p class="mb-0 ml-2 hours-list__total--time-chip">{{ totalWorkedTime }}</p>
              <p class="mb-0 mt-1 ml-2 hours-list__total--time-text2" v-if="workTime.length && workTime[0].date">for</p>
              <p class="mb-0 ml-2 hours-list__total--time-chip" v-if="workTime.length && workTime[0].date">{{ startDate }} - {{ endDate }}</p>
              <v-btn
                text
                color="#1976D2"
                small
                @click="resetWorkTime"
              >
                Reset
              </v-btn>
            </div>
          </div>
          <div class="d-flex align-center justify-center" v-if="workTime.length < 10">
            <v-btn
              v-if="!staffReviewStatus"
              class="mx-2"
              fab
              dark
              x-small
              color="#1976D2"
              @click="addHoursItem"
            >
              <v-icon dark style="margin: 0 0 0 1px;">
                mdi-plus
              </v-icon>
            </v-btn>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-end hours-submission__action">
      <v-btn 
        text
        :loading="loading"
        @click="closeModalWindow"
        >
        Cancel
      </v-btn>
      <v-btn
        depressed
        color="#1976D2"
        class="request-btn white--text ml-2"
        :loading="loading"
        :disabled="submitButtonDisable"
        @click="handleSubmit"
      >
        {{ !staffHoursData.length 
          ? 'Submit Time' 
            : staffReviewStatus
            ? 'Approve Time'
          : 'Re-Submit Time' }}
      </v-btn>
    </div>
  </v-card>
</template>

<script>
import { staffHoursSubmision, updateStaffHoursStatus, updateStaffHours, processAssignment } from "@/utils/newDbUtils";
import { assign } from "lodash";
import { readonly } from "vue";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "ADHoursSubmission",
  data() {
    return {
      logDateMenu: false,
      logDate: null,
      workedHours: null,
      workedMinutesList: [0, 15, 30, 45],
      workedMinutes: null,
      note: null,
      workTime: [],
      workTimeStartClone: [],
      loading: false
    };
  },
  watch: {
    hoursSubmissionModalOpen() {
      if (this.hoursSubmissionModalOpen) {
        if (this.staffHoursData.length) {
          this.staffHoursData.forEach((item) => {
            this.workTime.push({
              date: `${item.date[0]}-${item.date[1]}-${item.date[2]}`,
              workedHours: item.workedHours,
              workedMinutes: item.workedMinutes,
              note: item.note,
              logDateMenu: false
            })
          })
          this.workTimeStartClone = _.cloneDeep(this.workTime);
        }
      }
    }
  },
  computed: {
    ...mapGetters("assignment", [
      "hoursSubmissionAssignmentInfo",
      "staffHoursData",
      "hoursSubmissionModalOpen",
      "staffReviewStatus"
    ]),
    ...mapGetters("auth", ["user"]),
    formattedLogDate: {
      get() {
        return this.formatPickerDate(this.logDate);
      },
      set() {
        this.logDate = null;
      },
    },
    submitButtonDisable() {
      let disable = true;
      let time = this.workTime.find((item) => 
        !item.date
      )
      if (this.workTime.length && !time) {
        disable = false;
      }
      return disable;
    },
    startDate() {
      if (this.workTime.length === 0) return "";
      let dates = this.workTime
        .map(item => {
          let [year, month, day] = item.date.split("-").map(Number);
          return new Date(year, month - 1, day);
        })
        .filter(date => !isNaN(date));
      return this.formatDateString(new Date(Math.min(...dates)));
    },
    endDate() {
      if (this.workTime.length === 0) return "";

      let dates = this.workTime
        .map(item => {
          let [year, month, day] = item.date.split("-").map(Number);
          return new Date(year, month - 1, day);
        })
        .filter(date => !isNaN(date));

      return this.formatDateString(new Date(Math.max(...dates)));
    },
    totalWorkedTime() {
      let totalHours = 0;
      let totalMinutes = 0;
      this.workTime.forEach(item => { 
        totalHours += +item.workedHours || 0; 
        totalMinutes += +item.workedMinutes || 0; 
      });
      totalHours += Math.floor(totalMinutes / 60);
      totalMinutes = totalMinutes % 60;
      return `${totalHours}h ${totalMinutes}m`;
    },
    totalStartWorkTime() {
      let totalHours = 0;
      let totalMinutes = 0;
      this.workTimeStartClone.forEach(item => { 
        totalHours += +item.workedHours || 0; 
        totalMinutes += +item.workedMinutes || 0; 
      });
      totalHours += Math.floor(totalMinutes / 60);
      totalMinutes = totalMinutes % 60;
      return `${totalHours}h ${totalMinutes}m`;
    },
    startWorkTimeStartDate() {
      if (this.workTimeStartClone.length === 0) return "";
      let dates = this.workTimeStartClone
        .map(item => {
          let [year, month, day] = item.date.split("-").map(Number);
          return new Date(year, month - 1, day);
        })
        .filter(date => !isNaN(date));
      return this.formatDateString(new Date(Math.min(...dates)));
    },

    startWorkTimeEndDate() {
      if (this.workTimeStartClone.length === 0) return "";
      let dates = this.workTimeStartClone
        .map(item => {
          let [year, month, day] = item.date.split("-").map(Number);
          return new Date(year, month - 1, day);
        })
        .filter(date => !isNaN(date));
      return this.formatDateString(new Date(Math.max(...dates)));
    },
  },
  methods: {
    ...mapActions("assignment", [
      "setHoursSubmissionModalOpen",
      "setStaffHoursData",
      "openReviewModal"
    ]),
    ...mapActions("flashMessage", ["handleFlash"]),
    resetWorkTime() {
      this.workTime = _.cloneDeep(this.workTimeStartClone);
    },
    formatPickerDate(date) {
      if (!date) return "";

      const parsedDate = new Date(date);
      if (isNaN(parsedDate)) return "Date is not valid";
      return new Intl.DateTimeFormat("en-US", { day: "2-digit", month: "long", year: "numeric" }).format(parsedDate);
    },
    formatDateString(date) {
      const dateString = date.toString();
      const dateParts = dateString.split(" ");
      const day = dateParts[2];
      const month = dateParts[1];
      const year = dateParts[3];
      const months = {
        Jan: "January", Feb: "February", Mar: "March", Apr: "April",
        May: "May", Jun: "June", Jul: "July", Aug: "August",
        Sep: "September", Oct: "October", Nov: "November", Dec: "December"
      };

      return `${day} ${months[month]} ${year}`;
    },
    hoursRule(value) {
      if (value === null || value === "") return true;
      if (value < 0) return "Worked hours cannot be less than 0";
      if (value > 24) return "Worked hours cannot be greater than 24";
      return true;
    },
    validateHours() {
      if (this.workedHours < 0) {
        this.workedHours = 0;
      } else if (this.workedHours > 24) {
        this.workedHours = 24;
      }
    },
    handleHoursInput(index, value) {
      if (value < 0) {
        this.workTime[index].workedHours = 0;
      } else if (value > 24) {
        this.workTime[index].workedHours = 24;
      } else {
        this.workTime[index].workedHours = +value;
      }
    },
    minutesRule(value) {
      if (value === null || value === "") return true;
      if (value < 0) return "Worked minutes cannot be less than 0";
      if (value > 60) return "Worked minutes cannot be greater than 60";
      return true;
    },
    validateMinutes() {
      if (this.workedMinutes < 0) {
        this.workedMinutes = 0;
      } else if (this.workedMinutes > 60) {
        this.workedMinutes = 60;
      }
    },
    convertToDecimal() {
      let hours = 0;
      let minutes = 0;
      this.workTimeStartClone.forEach(item => {
        hours += +item.workedHours || 0;
        minutes += +item.workedMinutes || 0;
      })
      return hours + minutes / 60;
    },
    addHoursItem() {
      if (this.workTime.length < 10) {
        this.workTime.push({
          date: "",
          workedHours: 0,
          workedMinutes: 0,
          note: "",
          logDateMenu: false
        })
      }
    },
    removeHoursItem(index) {
      this.workTime.splice(index, 1);
    },
    async handleSubmit() {
      const formatDate = (dateString) => {
        const [year, month, day] = dateString.split('-');
        const formattedMonth = month.length === 1 ? '0' + month : month; const formattedDay = day.length === 1 ? '0' + day : day;
        return `${year}-${formattedMonth}-${formattedDay}`;
      };
      try {
        this.loading = true;
        if (this.staffHoursData.length && !this.staffReviewStatus) {
          this.workTime.forEach((item) => {
            item.date = formatDate(item.date);
            delete item.logDateMenu
          })
          const data = {
            staffHoursId: this.staffHoursData[0].staffHoursId,
            workTime: this.workTime,
            assignmentId: this.hoursSubmissionAssignmentInfo && this.hoursSubmissionAssignmentInfo.id,
            staffId: this.user && this.user.user?.id
          }
          const resp = await updateStaffHours(data);
          if (resp.status === 200) {
            this.handleFlash({ response: { status: 200 }, show: true });
            this.$emit("assignment-dashboard-table-refresh");
          } else {
            this.handleFlash({ response: {data: {message: "Something went wrong", statusCode: 500}}, show: true});
          }
        } else if (this.staffReviewStatus) {
          const response = await updateStaffHoursStatus({
            staffHoursId: this.staffHoursData[0].staffHoursId,
            status: {
              status: "APPROVED"
            },
          })
          if (response.status === 200) {
            const assignmentUpdateResp = await processAssignment({
              data: {
                id: this.hoursSubmissionAssignmentInfo.id,
                pricing: {
                  workTypes: [
                    {
                      quantity: this.convertToDecimal(),
                      orderPosition: 1
                    }
                  ]
                }
              },
              batchEdit: true,
              id: this.hoursSubmissionAssignmentInfo.id
            });
            if (assignmentUpdateResp.status === 200) {
              this.handleFlash({ response: { status: 200 }, show: true });
              this.$emit("assignment-dashboard-table-refresh");
            } else {
              this.handleFlash({ response: {data: {message: "Something went wrong", statusCode: 500}}, show: true});
            }
          } else {
            this.handleFlash({ response: {data: {message: "Something went wrong", statusCode: 500}}, show: true});
          }
        } else {
          this.workTime.forEach((item) => {
            item.date = formatDate(item.date);
            delete item.logDateMenu
          })
          const data = {
            workTime: this.workTime,
            assignmentId: this.hoursSubmissionAssignmentInfo && this.hoursSubmissionAssignmentInfo.id,
            staffId: this.user && this.user.user?.id
          }
          const resp = await staffHoursSubmision(data);
          if (resp.status === 200) {
            this.handleFlash({ response: { status: 200 }, show: true });
            this.$emit("assignment-dashboard-table-refresh");
          } else {
            this.handleFlash({ response: {data: {message: "Something went wrong", statusCode: 500}}, show: true});
          }
        }
        this.loading = false;
        this.closeModalWindow();
      } catch (err) {
        console.error(err);
        this.handleFlash({ response: {data: {message: "Something went wrong", statusCode: 500}}, show: true});
        this.loading = false;
        this.closeModalWindow();
      }
    },
    closeModalWindow() {
      this.workTime = [];
      this.workTimeStartClone = [];
      this.setStaffHoursData([]);
      this.openReviewModal(false);
      this.setHoursSubmissionModalOpen({status: false});
    }
  },
  mounted() {
    this.workTime = [];
    if (this.staffHoursData.length) {
      this.staffHoursData.forEach((item) => {
        this.workTime.push({
          date: `${item.date[0]}-${item.date[1]}-${item.date[2]}`,
          workedHours: item.workedHours,
          workedMinutes: item.workedMinutes,
          note: item.note,
          logDateMenu: false
        })
      })
      this.workTimeStartClone = _.cloneDeep(this.workTime);
    }
  }
};
</script>

<style lang="scss" scoped>
.hours-submission {
  padding: 15px;
  background-color: #E0E0E0;
  &__container {
    padding: 20px;
    border-radius: 4px;
    background-color: #fff;
    &--title {
      margin-bottom: 30px;
      & h6 {
        font-family: Roboto;
        font-size: 20px;
        font-weight: 500;
        line-height: 18px;
        text-align: left;
      }
    }
    & .hours-list {
      &__total {
        margin-top: 32px;
        .v-btn--fab.v-size--small {
          width: 22px !important;
          height: 22px !important;
        }
        &--time {
          &-text {
            font-family: Roboto;
            font-size: 16px;
            font-weight: 600;
            line-height: 18px;
            text-align: left;
          }
          &-text2 {
            font-family: Roboto;
            font-size: 14px;
            font-weight: 300;
            line-height: 18px;
            text-align: left;
          }
          &-chip {
            font-family: Roboto;
            font-size: 14px;
            font-weight: 300;
            line-height: 18px;
            text-align: left;
            background-color: #E0E0E0;
            border-radius: 8px;
            padding: 3px 12px 0 12px;
          }
        } 
      }
      &__item {
        &-main {
          width: 90%;
        }
        &-remove {
          width: 10%;
          margin-top: 10px;
        }
      }
    }
  }
  &__action {
    margin-top: 15px;
  }
}

.mt-20px {
  margin-top: 20px;
}
</style>