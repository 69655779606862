<template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
    class="ad-new-assignment v-card v-sheet theme--light ma-4"
  >
    <v-tabs v-model="tab" background-color="transparent" grow>
      <v-tab v-for="step in steps" :key="step">
        {{ step }}
      </v-tab>
      <v-btn
        v-if="newAssignmentDatas.length > 1"
        class="ma-1"
        height="40px"
        small
        color="secondary"
        @click="handleAssignmentItemDeletion"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-container>
          <v-row>
            <v-col cols="12" :sm="bulkEditing ? 4 : 6" class="pb-0">
              <v-text-field
                v-if="bulkEditing"
                outlined
                label="Related Project"
                v-model="bulkEditingProjectName"
                disabled
              ></v-text-field>
              <v-autocomplete
                v-else
                outlined
                label="Related Project"
                :items="projectsList"
                v-model="relatedProject"
                item-text="name"
                return-object
                :rules="[rules.required]"
                @change="updateRelatedProjectSmartFields($event)"
              ></v-autocomplete>
            </v-col>
            <v-col v-if="bulkEditing" cols="12" :sm="4" class="pb-0 anounce-text-container">
              <v-autocomplete
                outlined
                label="Project Manager"
                :items="driList"
                v-model="projectManager"
                :item-text="fullName"
                return-object
                :disabled="bulkQueryEdit"
                :filled="bulkQueryEdit"
                :background-color="bulkEditing && this.newAssignmentDatas[0].projectManager !== null ? '#E3F2FD' : ''"
              ></v-autocomplete>
              <p 
                class="position-absolute anounce-text"
                style="color: #1E88E5"
                v-if="bulkEditing && this.newAssignmentDatas[0].projectManager !== null ? '#E3F2FD' : ''">
                Value was changed
              </p>
            </v-col>
            <v-col cols="12" :sm="bulkEditing ? 4 : 6" class="pb-0 anounce-text-container">
              <v-menu
                v-model="requestDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="formattedRequestDate"
                    :rules="bulkEditing ? [] : [rules.required]"
                    label="Request Date"
                    prepend-icon="mdi-calendar-plus"
                    readonly
                    :error="conversionErrors"
                    :clearable="bulkEditing"
                    v-bind="attrs"
                    v-on="on"
                    :background-color="bulkEditing && newAssignmentDatas[0].schedule.requestDate !== null ? '#E3F2FD' : ''"
                  ></v-text-field>
                </template>
                <v-date-picker
                  @input="requestDateMenu = false"
                  v-model="requestDate"
                ></v-date-picker>
              </v-menu>
              <p 
                class="position-absolute anounce-text anounce-text-date"
                style="color: #1E88E5"
                v-if="bulkEditing && newAssignmentDatas[0].schedule.requestDate !== null">
                Value was changed
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="pb-0 anounce-text-container">
              <v-textarea
                outlined
                clearable
                rows="1"
                clear-icon="mdi-close-circle-outline"
                label="Email Subject Line"
                v-model="emailSubjectLine"
                :rules="isCue || bulkEditing ? [] : [rules.required]"
                :background-color="bulkEditing && this.newAssignmentDatas[0].assignmentDetails.emailSubjectLine !== null ? '#E3F2FD' : ''"
              />
              <p 
                class="position-absolute anounce-text"
                style="color: #1E88E5"
                v-if="bulkEditing && this.newAssignmentDatas[0].assignmentDetails.emailSubjectLine !== null ? '#E3F2FD' : ''">
                Value was changed
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="4" class="pb-0 anounce-text-container">
              <v-autocomplete
                outlined
                label="Related Client Requester"
                :item-text="fullName"
                return-object
                :items="clientRequesters"
                v-model="clientRequester"
                :rules="bulkEditing ? [] : [rules.requiredObject]"
                :background-color="bulkEditing && this.newAssignmentDatas[0].clientRequester !== null ? '#E3F2FD' : ''"
              ></v-autocomplete>
              <p 
                class="position-absolute anounce-text"
                style="color: #1E88E5"
                v-if="bulkEditing && this.newAssignmentDatas[0].clientRequester !== null ? '#E3F2FD' : ''">
                Value was changed
              </p>
            </v-col>
            <v-col cols="12" sm="4" class="pb-0 anounce-text-container">
              <v-autocomplete
                outlined
                label="Request Type"
                :items="requestTypeOptions"
                :loading="dropdownsLoading"
                v-model="requestType"
                :rules="bulkEditing ? [] : [rules.required]"
                @change="handleWorkType1Quantity"
                :background-color="bulkEditing && this.newAssignmentDatas[0].assignmentDetails.requestType !== null ? '#E3F2FD' : ''"
              ></v-autocomplete>
              <p 
                class="position-absolute anounce-text"
                style="color: #1E88E5"
                v-if="bulkEditing && this.newAssignmentDatas[0].assignmentDetails.requestType !== null ? '#E3F2FD' : ''">
                Value was changed
              </p>
            </v-col>
            <v-col cols="12" sm="4" class="pb-0 anounce-text-container">
              <v-autocomplete
                outlined
                label="Media Type"
                :items="mediaTypeOptions"
                :loading="dropdownsLoading"
                v-model="mediaType"
                :rules="isCue || bulkEditing ? [] : [rules.required]"
                :background-color="bulkEditing && this.newAssignmentDatas[0].assignmentDetails.mediaType !== null ? '#E3F2FD' : ''"
              ></v-autocomplete>
              <p 
                class="position-absolute anounce-text"
                style="color: #1E88E5"
                v-if="bulkEditing && this.newAssignmentDatas[0].assignmentDetails.mediaType !== null ? '#E3F2FD' : ''">
                Value was changed
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" :sm="bulkEditing ? 6 : 3" class="pb-0 anounce-text-container">
              <v-autocomplete
                outlined
                label="Keyword"
                :items="keywords"
                :loading="dropdownsLoading"
                v-model="keyword"
                :rules="isCue || bulkEditing ? [] : [rules.required]"
                :background-color="bulkEditing && this.newAssignmentDatas[0].assignmentDetails.keyword !== null ? '#E3F2FD' : ''"
              ></v-autocomplete>
              <p 
                class="position-absolute anounce-text"
                style="color: #1E88E5"
                v-if="bulkEditing && this.newAssignmentDatas[0].assignmentDetails.keyword !== null ? '#E3F2FD' : ''">
                Value was changed
              </p>
            </v-col>
            <v-col v-if="!bulkEditing" cols="12" sm="3" class="pb-0">
              <v-text-field
                outlined
                label="Task ID"
                v-model="taskID"
                :rules="
                  requestType === 'Meeting' || isCue ? [] : [rules.required]
                "
              />
            </v-col>
            <v-col v-if="!bulkEditing" cols="12" sm="3" class="pb-0">
              <v-text-field
                outlined
                label="Assignment ID"
                v-model="assignmentID"
              />
            </v-col>
            <v-col cols="12" :sm="bulkEditing ? 6 : 3" class="pb-0 anounce-text-container">
              <v-text-field outlined label="Genre" v-model="genre" :background-color="bulkEditing && this.newAssignmentDatas[0].assignmentDetails.genre !== null ? '#E3F2FD' : ''"/>
              <p 
                class="position-absolute anounce-text"
                style="color: #1E88E5"
                v-if="bulkEditing && this.newAssignmentDatas[0].assignmentDetails.genre !== null ? '#E3F2FD' : ''">
                Value was changed
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" class="pb-0 anounce-text-container">
              <v-combobox
                ref="titleBox"
                outlined
                label="Assignment Title"
                :items="assignmentTitles"
                v-model="assignmentTitle"
                :rules="bulkEditing ? [] : [rules.required]"
                :background-color="bulkEditing && this.newAssignmentDatas[0].assignmentDetails.assignmentTitle !== null ? '#E3F2FD' : ''"
              ></v-combobox>
              <p 
                class="position-absolute anounce-text"
                style="color: #1E88E5"
                v-if="bulkEditing && this.newAssignmentDatas[0].assignmentDetails.assignmentTitle !== null ? '#E3F2FD' : ''">
                Value was changed
              </p>
            </v-col>
            <v-col cols="12" sm="6" class="pb-0 anounce-text-container">
              <v-text-field
                outlined
                label="Assignment Detail"
                v-model="assignmentDetails"
                :rules="bulkEditing ? [] : [rules.required]"
                :background-color="bulkEditing && this.newAssignmentDatas[0].assignmentDetails.assignmentDetail !== null ? '#E3F2FD' : ''"
              />
              <p 
                class="position-absolute anounce-text"
                style="color: #1E88E5"
                v-if="bulkEditing && this.newAssignmentDatas[0].assignmentDetails.assignmentDetail !== null ? '#E3F2FD' : ''">
                Value was changed
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="3" class="pb-0 anounce-text-container">
              <v-autocomplete
                outlined
                label="Source GEO"
                :items="sourceGeos"
                v-model="sourceGeo"
                :loading="dropdownsLoading"
                :disabled="bulkQueryEdit"
                :filled="bulkQueryEdit"
                :background-color="bulkEditing && this.newAssignmentDatas[0].assignmentDetails.sourceGeo !== null ? '#E3F2FD' : ''"
              ></v-autocomplete>
              <p 
                class="position-absolute anounce-text"
                style="color: #1E88E5"
                v-if="bulkEditing && this.newAssignmentDatas[0].assignmentDetails.sourceGeo !== null ? '#E3F2FD' : ''">
                Value was changed
              </p>
            </v-col>
            <v-col cols="12" sm="3" class="pb-0 anounce-text-container">
              <v-autocomplete
                outlined
                label="GEO"
                :items="mainGeoOptions"
                v-model="geo"
                :rules="bulkEditing ? [] : [rules.required]"
                :background-color="bulkEditing && this.newAssignmentDatas[0].assignmentDetails.geo !== null ? '#E3F2FD' : ''"
                @change="getStaffByGeo($event)"
              ></v-autocomplete>
              <p 
                class="position-absolute anounce-text"
                style="color: #1E88E5"
                v-if="bulkEditing && this.newAssignmentDatas[0].assignmentDetails.geo !== null ? '#E3F2FD' : ''">
                Value was changed
              </p>
            </v-col>
            <v-col cols="12" sm="3" class="pb-0 anounce-text-container">
              <v-autocomplete
                outlined
                label="Related Purchase Order"
                :items="relatedPurchaseOrders"
                v-model="po"
                item-text="purchaseOrderNumber"
                return-object
                :background-color="bulkEditing && this.newAssignmentDatas[0].pricing.purchaseOrder !== null ? '#E3F2FD' : ''"
              ></v-autocomplete>
              <p 
                class="position-absolute anounce-text"
                style="color: #1E88E5"
                v-if="bulkEditing && this.newAssignmentDatas[0].pricing.purchaseOrder !== null ? '#E3F2FD' : ''">
                Value was changed
              </p>
            </v-col>
            <v-col v-if="!bulkEditing" cols="12" sm="3" class="pb-0">
              <v-file-input
                outlined
                v-model="docBlob"
                accept=".pdf"
                truncate-length="20"
                label="Document"
                :loading="document ? docLoading : false"
                :disabled="document ? docLoading : false"
                @change="convertDoc($event)"
                :rules="[rules.isPDF]"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" class="pb-0 anounce-text-container">
              <v-text-field
                outlined
                label="Word Count"
                type="number"
                v-model="wordCount"
                :loading="loadWritingDueDate || loadEditingDueDate"
                :disabled="loadWritingDueDate || loadEditingDueDate || bulkQueryEdit"
                :filled="bulkQueryEdit"
                :rules="requestType === 'Localization' ? [] : []"
                :background-color="bulkEditing && this.newAssignmentDatas[0].assignmentDetails.wordCount !== null ? '#E3F2FD' : ''"
                @change="handleWorkType1Quantity"
              />
              <p 
                class="position-absolute anounce-text"
                style="color: #1E88E5"
                v-if="bulkEditing && this.newAssignmentDatas[0].assignmentDetails.wordCount !== null ? '#E3F2FD' : ''">
                Value was changed
              </p>
            </v-col>
            <v-col cols="12" sm="6" class="pb-0 anounce-text-container">
              <v-text-field
                outlined
                label="Character Count"
                type="number"
                v-model="characterCount"
                :loading="loadWritingDueDate || loadEditingDueDate"
                :disabled="loadWritingDueDate || loadEditingDueDate || bulkQueryEdit"
                :filled="bulkQueryEdit"
                :rules="requestType === 'Localization' ? [] : []"
                :background-color="bulkEditing && this.newAssignmentDatas[0].assignmentDetails.characterCount !== null ? '#E3F2FD' : ''"
              />
              <p 
                class="position-absolute anounce-text"
                style="color: #1E88E5"
                v-if="bulkEditing && this.newAssignmentDatas[0].assignmentDetails.characterCount !== null ? '#E3F2FD' : ''">
                Value was changed
              </p>
            </v-col>
          </v-row>
          <v-row v-if="(isEditMode || bulkEditing) && !bulkQueryEdit" class="align-center">
            <v-col cols="12" sm="4" class="d-flex justify-center pb-0 anounce-text-container">
              <v-checkbox
                class="mt-0 pt-0"
                prepend-icon="mdi-account-eye"
                label="Client Reviewed"
                :indeterminate="bulkEditing && differentReviewedValues ? true : false"
                v-model="clientReviewed"
              ></v-checkbox>
              <p 
                class="position-absolute anounce-text-checkbox"
                style="color: #1E88E5"
                v-if="bulkEditing && !differentReviewedValues && newAssignmentDatas[0].assignmentDetails.clientReviewed !== null ? '#E3F2FD' : ''">
                Value was changed
              </p>
              <p 
                class="position-absolute anounce-text-checkbox"
                style="color: #646464"
                v-if="differentReviewedValues">
                Different values
              </p>
            </v-col>
            <v-col cols="12" sm="4" class="d-flex justify-center pb-0 anounce-text-container">
              <v-checkbox
                class="mt-0 pt-0"
                prepend-icon="mdi-account-voice"
                label="Client Feedback"
                :indeterminate="bulkEditing && differentFeedbackValues ? true : false"
                v-model="clientFeedback"
              ></v-checkbox>
              <p 
                class="position-absolute anounce-text-checkbox"
                style="color: #1E88E5"
                v-if="bulkEditing && !differentFeedbackValues && newAssignmentDatas[0].assignmentDetails.clientFeedback !== null ? '#E3F2FD' : ''">
                Value was changed
              </p>
              <p 
                class="position-absolute anounce-text-checkbox"
                style="color: #646464"
                v-if="differentFeedbackValues">
                Different values
              </p>
            </v-col>
            <v-col cols="12" sm="4" class="d-flex justify-center pb-0 anounce-text-container">
              <v-checkbox
                class="mt-0 pt-0"
                prepend-icon="mdi-cash"
                label="Billed"
                :indeterminate="bulkEditing && differentBilledValues ? true : false"
                v-model="billed">
                </v-checkbox>
                <p 
                  class="position-absolute anounce-text-checkbox"
                  style="color: #1E88E5"
                  v-if="bulkEditing && !differentBilledValues && newAssignmentDatas[0].assignmentDetails.billed !== null ? '#E3F2FD' : ''">
                  Value was changed
                </p>
                <p 
                  class="position-absolute anounce-text-checkbox"
                  style="color: #646464"
                  v-if="differentBilledValues">
                  Different values
                </p>
              </v-col>
          </v-row>
        </v-container>
      </v-tab-item>
      <v-tab-item eager>
        <v-container>
          <v-row>
            <v-col cols="12" sm="4" class="pb-0 anounce-text-container">
              <v-menu
                v-model="clientDeliveryDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="formattedClientDeliveryDate"
                    label="Delivery Date (Client)"
                    prepend-icon="mdi-calendar"
                    readonly
                    :error="conversionErrors"
                    v-bind="attrs"
                    v-on="on"
                    :disabled="bulkQueryEdit"
                    :filled="bulkQueryEdit"
                    :background-color="bulkEditing && newAssignmentDatas[0]?.clientDeliveryDate !== null ? '#E3F2FD' : ''"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="clientDeliveryDate"
                  @input="clientDeliveryDateMenu = false"
                  @change="handlePayPeriod($event)"
                ></v-date-picker>
              </v-menu>
              <p 
                class="position-absolute anounce-text anounce-text-date"
                v-if="bulkEditing && newAssignmentDatas[0]?.clientDeliveryDate !== null ? '#E3F2FD' : ''">
                Value was changed
              </p>
            </v-col>
            <v-col cols="12" sm="4" class="pb-0 anounce-text-container">
              <v-select
                :items="clientDeliveryDueHours"
                v-model="clientDeliveryDue"
                item-text="time"
                label="Delivery Due (Time of Day)"
                prepend-icon="mdi-clock-time-five-outline"
                :error="conversionErrors"
                :disabled="bulkQueryEdit"
                :filled="bulkQueryEdit"
                :background-color="bulkEditing && newAssignmentDatas[0]?.clientDeliveryDue !== null ? '#E3F2FD' : ''"
              ></v-select>
              <p 
                class="position-absolute anounce-text anounce-text-date"
                v-if="bulkEditing && newAssignmentDatas[0]?.clientDeliveryDue !== null ? '#E3F2FD' : ''">
                Value was changed
              </p>
            </v-col>
            <v-col cols="12" sm="4" class="pb-0">
              <v-autocomplete
                outlined
                append-icon="mdi-airplane-clock"
                label="Time Zone"
                :error="conversionErrors"
                v-model="timeZone"
                :items="utcTimeZones"
                :disabled="bulkQueryEdit"
                :filled="bulkQueryEdit"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="4" class="pb-0 anounce-text-container">
              <v-menu
                v-model="deliveryDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="formattedDeliveryDate"
                    :rules="bulkEditing ? [] : [rules.required]"
                    label="Delivery Date (ET)"
                    prepend-icon="mdi-calendar-check"
                    readonly
                    :clearable="bulkEditing"
                    v-bind="attrs"
                    v-on="on"
                    :disabled="bulkQueryEdit"
                    :filled="bulkQueryEdit"
                    :background-color="bulkEditing && newAssignmentDatas[0]?.schedule?.deliveryDate !== null ? '#E3F2FD' : ''"
                  ></v-text-field>
                </template>
                <v-date-picker
                  @input="deliveryDateMenu = false"
                  v-model="deliveryDate"
                ></v-date-picker>
                <p
                  class="position-absolute anounce-text anounce-text-date"
                  v-if="bulkEditing && newAssignmentDatas[0]?.schedule?.deliveryDate !== null ? '#E3F2FD' : ''"
                >
                  Value was changed
                </p>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="4" class="pb-0 anounce-text-container">
              <v-select
                :items="deliveryDueHoursList"
                v-model="deliveryDue"
                :rules="bulkEditing ? [] : [rules.required]"
                item-text="time"
                label="Delivery Due (ET)"
                prepend-icon="mdi-clock-check-outline"
                :clearable="bulkEditing"
                :disabled="bulkQueryEdit"
                :filled="bulkQueryEdit"
                :background-color="bulkEditing && newAssignmentDatas[0]?.schedule?.deliveryTime !== null ? '#E3F2FD' : ''"
              ></v-select>
              <p
                class="position-absolute anounce-text anounce-text-date"
                v-if="bulkEditing && newAssignmentDatas[0]?.schedule?.deliveryTime !== null ? '#E3F2FD' : ''"
              >
                Value was changed
              </p>
            </v-col>
            <v-col cols="12" sm="4" class="pb-0">
              <v-btn
                :disabled="bulkQueryEdit"
                :filled="bulkQueryEdit"
                class="px-3"
                height="56px"
                color="accent"
                outlined
                @click="convertDeliveryDue"
                >Convert Delivery Due to EST time zone</v-btn
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="4" class="pb-0 anounce-text-container">
              <v-autocomplete
                outlined
                label="Primary"
                :item-text="fullName"
                :items="relatedWriters"
                return-object
                v-model="writer"
                :class="{ capacityNote: errorForWritingCapacity }"
                :error="errorForWritingCapacity"
                :error-messages="
                  errorForWritingCapacity
                    ? ['Person\'s daily workload limit reached.']
                    : []
                "
                :clearable="bulkEditing"
                :disabled="bulkQueryEdit || (bulkEditing && relatedWriters.length === 0)"
                :filled="bulkQueryEdit"
                :rules="bulkEditing ? [] : [rules.requiredObject, rules.availabile('writer')]"
                @input="getCapacity($event, 'Writing')"
                @change="resetSpecialistAvailabilityStatus('writer')"
                :background-color="bulkEditing && this.newAssignmentDatas[0].primary !== null ? '#E3F2FD' : ''"
              ></v-autocomplete>
              <p 
                class="position-absolute anounce-text"
                style="color: #1E88E5"
                v-if="bulkEditing && this.newAssignmentDatas[0].primary !== null ? '#E3F2FD' : ''">
                Value was changed
              </p>
            </v-col>
            <v-col cols="12" sm="4" class="pb-0 anounce-text-container">
              <v-menu
                v-model="writingDueDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    ref="writingDueDateField"
                    v-model="formattedWritingDueDate"
                    :rules="
                      bulkEditing
                        ? [rules.dateAvailabile('writing')]
                        : [rules.required, rules.dateAvailabile('writing')]
                    "
                    label="For Primary"
                    prepend-icon="mdi-calendar-edit"
                    readonly
                    :hint="bulkEditing && newAssignmentDatas[0].schedule.primaryDueDate !== null ? '' : 'Primary Due Date (ET)'"
                    persistent-hint
                    :loading="loadWritingDueDate"
                    :disabled="loadWritingDueDate || bulkQueryEdit"
                    :filled="bulkQueryEdit"
                    :clearable="bulkEditing"
                    v-bind="attrs"
                    v-on="on"
                    :background-color="bulkEditing && newAssignmentDatas[0].schedule.primaryDueDate !== null ? '#E3F2FD' : ''"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="writingDueDate"
                  :max="override ? null : deliveryDate"
                  @input="writingDueDateMenu = false"
                  @change="handleDueDate('writing', 'relatedWriters', 'writer')"
                ></v-date-picker>
              </v-menu>
              <p
                class="position-absolute anounce-text anounce-text-date"
                v-if="bulkEditing && newAssignmentDatas[0].schedule.primaryDueDate !== null ? '#E3F2FD' : ''"
              >
                Value was changed
              </p>
            </v-col>
            <v-col cols="12" sm="4" class="pb-0 anounce-text-container">
              <v-select
                :disabled="bulkQueryEdit"
                :filled="bulkQueryEdit"
                :items="writingDueHours"
                v-model="writingDue"
                :rules="
                  bulkEditing
                    ? [rules.timeAvailabile('writing')]
                    : [rules.required, rules.timeAvailabile('writing')]
                "
                item-text="time"
                label="For Primary"
                prepend-icon="mdi-clock-time-eight-outline"
                :hint="bulkEditing && newAssignmentDatas[0].schedule.primaryDueTime !== null ? '' : 'Primary Due (ET)'"
                persistent-hint
                :background-color="bulkEditing && newAssignmentDatas[0].schedule.primaryDueTime !== null ? '#E3F2FD' : ''"
              ></v-select>
              <p
                class="position-absolute anounce-text anounce-text-date"
                v-if="bulkEditing && newAssignmentDatas[0].schedule.primaryDueTime !== null ? '#E3F2FD' : ''"
              >
                Value was changed
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="4" class="pb-0 anounce-text-container">
              <v-autocomplete
                outlined
                label="Secondary"
                :item-text="fullName"
                :items="relatedEditors"
                return-object
                v-model="editor"
                :disabled="bulkQueryEdit || (bulkEditing && relatedEditors.length === 0)"
                :filled="bulkQueryEdit"
                :class="{ capacityNote: errorForEditingCapacity }"
                :error="errorForEditingCapacity"
                :error-messages="
                  errorForEditingCapacity
                    ? ['Person\'s daily workload limit reached.']
                    : []
                "
                
                :rules="bulkEditing ? [] : [rules.availabile('editor')]"
                @input="getCapacity($event, 'Editing')"
                @change="resetSpecialistAvailabilityStatus('editor')"
                :background-color="bulkEditing && this.newAssignmentDatas[0].secondary !== null ? '#E3F2FD' : ''"
              ></v-autocomplete>
              <p 
                class="position-absolute anounce-text"
                style="color: #1E88E5"
                v-if="bulkEditing && this.newAssignmentDatas[0].secondary !== null ? '#E3F2FD' : ''">
                Value was changed
              </p>
            </v-col>
            <v-col cols="12" sm="4" class="pb-0 anounce-text-container">
              <v-menu
                v-model="editingDueDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    ref="editingDueDateField"
                    v-model="formattedEditingDueDate"
                    :rules="[rules.dateAvailabile('editing')]"
                    label="For Secondary"
                    prepend-icon="mdi-calendar-star"
                    readonly
                    clearable
                    :hint="bulkEditing && newAssignmentDatas[0].schedule.secondaryDueDate !== null ? '' : 'Secondary Due Date (ET)'"
                    persistent-hint
                    :loading="loadEditingDueDate"
                    :disabled="loadEditingDueDate || bulkQueryEdit"
                    :filled="bulkQueryEdit"
                    v-bind="attrs"
                    v-on="on"
                    :background-color="bulkEditing && newAssignmentDatas[0]?.schedule.secondaryDueDate !== null ? '#E3F2FD' : ''"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="editingDueDate"
                  :max="override ? null : deliveryDate"
                  @input="editingDueDateMenu = false"
                  @change="handleDueDate('editing', 'relatedEditors', 'editor')"
                ></v-date-picker>
              </v-menu>
              <p
                class="position-absolute anounce-text anounce-text-date"
                v-if="bulkEditing && newAssignmentDatas[0].schedule.secondaryDueDate !== null ? '#E3F2FD' : ''"
              >
                Value was changed
              </p>
            </v-col>
            <v-col cols="12" sm="4" class="pb-0 anounce-text-container">
              <v-select
                :disabled="bulkQueryEdit"
                :filled="bulkQueryEdit"
                :items="editingDueHours"
                v-model="editingDue"
                :rules="[rules.timeAvailabile('editing')]"
                item-text="time"
                label="For Secondary"
                prepend-icon="mdi-clock-time-eleven-outline"
                :hint="bulkEditing && newAssignmentDatas[0].schedule.secondaryDueTime !== null ? '' : 'Secondary Due (ET)'"
                persistent-hint
                clearable
                :background-color="bulkEditing && newAssignmentDatas[0].schedule.secondaryDueTime !== null ? '#E3F2FD' : ''"
              ></v-select>
              <p
                class="position-absolute anounce-text anounce-text-date"
                v-if="bulkEditing && newAssignmentDatas[0].schedule.secondaryDueTime !== null ? '#E3F2FD' : ''"
              >
                Value was changed
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="4" class="pb-0 anounce-text-container">
              <v-autocomplete
                :disabled="bulkQueryEdit || (bulkEditing && reviewers.length === 0)"
                :filled="bulkQueryEdit"
                outlined
                label="QC"
                :item-text="fullName"
                :items="reviewers"
                return-object
                v-model="reviewer"
                :rules="bulkEditing ? [] : [rules.availabile('reviewer')]"
                @change="resetSpecialistAvailabilityStatus('reviewer')"
                :background-color="bulkEditing && newAssignmentDatas[0].qc !== null ? '#E3F2FD' : ''"
              ></v-autocomplete>
              <p 
                class="position-absolute anounce-text"
                style="color: #1E88E5"
                v-if="bulkEditing && newAssignmentDatas[0].qc !== null ? '#E3F2FD' : ''">
                Value was changed
              </p>
            </v-col>
            <v-col cols="12" sm="4" class="pb-0 anounce-text-container">
              <v-menu
                v-model="reviewingDueDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :disabled="bulkQueryEdit"
                    :filled="bulkQueryEdit"
                    ref="reviewingDueDateField"
                    v-model="formattedReviewingDueDate"
                    :rules="[rules.dateAvailabile('reviewing')]"
                    label="For QC"
                    prepend-icon="mdi-calendar-search"
                    readonly
                    clearable
                    :hint="bulkEditing && newAssignmentDatas[0].schedule.qcDueDate !== null ? '' : 'QC Due Date (ET)'"
                    persistent-hint
                    v-bind="attrs"
                    v-on="on"
                    :background-color="bulkEditing && newAssignmentDatas[0].schedule.qcDueDate !== null ? '#E3F2FD' : ''"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="reviewingDueDate"
                  :max="override ? null : deliveryDate"
                  @input="reviewingDueDateMenu = false"
                  @change="handleDueDate('reviewing', 'reviewers', 'reviewer')"
                ></v-date-picker>
              </v-menu>
              <p
                class="position-absolute anounce-text anounce-text-date"
                v-if="bulkEditing && newAssignmentDatas[0].schedule.qcDueDate !== null ? '#E3F2FD' : ''"
              >
                Value was changed
              </p>
            </v-col>
            <v-col cols="12" sm="4" class="pb-0 anounce-text-container">
              <v-select
                :items="reviewingDueHours"
                v-model="reviewingDue"
                :rules="[rules.timeAvailabile('reviewing')]"
                item-text="time"
                label="For QC"
                prepend-icon="mdi-clock-time-two-outline"
                hint="QC Due (ET)"
                :disabled="bulkQueryEdit"
                :filled="bulkQueryEdit"
                persistent-hint
                clearable
                :background-color="bulkEditing && newAssignmentDatas[0].schedule.qcDueTime !== null ? '#E3F2FD' : ''"
              ></v-select>
              <p
                class="position-absolute anounce-text anounce-text-date"
                v-if="bulkEditing && newAssignmentDatas[0].schedule.qcDueTime !== null ? '#E3F2FD' : ''"
              >
                Value was changed
              </p>
            </v-col>
          </v-row>
          <v-row v-if="!bulkEditing">
            <v-col cols="12" sm="4">
              <v-checkbox
                class="mt-0"
                v-model="overrideStaff"
                label="Override Staff Availability"
                prepend-icon="mdi-account-multiple-remove"
                persistent-hint
                hint="If person agreed to perform while unavailable"
                :disabled="bulkQueryEdit"
                :filled="bulkQueryEdit"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" sm="4">
              <v-checkbox
                class="mt-0"
                v-model="urgent"
                label="Urgent"
                prepend-icon="mdi-clock-fast"
                persistent-hint
                hint="Convert Delivery data (ET) or enter it manually"
                readonly
                :color="urgent ? 'red accent-4' : ''"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" sm="4">
              <v-checkbox
                class="mt-0"
                v-model="override"
                label="Override"
                prepend-icon="mdi-calendar-remove"
                persistent-hint
                hint="Override Due Dates and Dues (Time of Day)"
                :disabled="!deliveryDate && !deliveryDue"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" class="pb-0 anounce-text-container">
              <v-menu
                v-model="payPeriodMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="formattedPayPeriod"
                    :rules="bulkEditing ? [] : [rules.required]"
                    label="Pay Period"
                    prepend-icon="mdi-calendar"
                    readonly
                    :clearable="bulkEditing"
                    v-bind="attrs"
                    v-on="on"
                    :background-color="bulkEditing && newAssignmentDatas[0].schedule.payPeriod !== null ? '#E3F2FD' : ''"
                  ></v-text-field>
                </template>
                <v-date-picker
                  @input="payPeriodMenu = false"
                  v-model="payPeriod"
                  type="month"
                ></v-date-picker>
              </v-menu>
              <p 
                class="position-absolute anounce-text anounce-text-date"
                style="color: #1E88E5"
                v-if="bulkEditing && newAssignmentDatas[0].schedule.payPeriod !== null">
                Value was changed
              </p>
            </v-col>
            <v-col cols="12" sm="6" class="pb-0 anounce-text-container">
              <v-autocomplete
                outlined
                label="File Location"
                :items="fileLocations"
                :loading="dropdownsLoading"
                v-model="fileLocation"
                :rules="isCue || bulkEditing ? [] : [rules.required]"
                :background-color="bulkEditing && this.newAssignmentDatas[0].assignmentDetails.fileLocation !== null ? '#E3F2FD' : ''"
              ></v-autocomplete>
              <p 
                class="position-absolute anounce-text"
                style="color: #1E88E5"
                v-if="bulkEditing && this.newAssignmentDatas[0].assignmentDetails.fileLocation !== null ? '#E3F2FD' : ''">
                Value was changed
              </p>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>
      <v-tab-item eager>
        <v-container>
          <v-row>
            <v-col cols="12" sm="6" class="pb-0 anounce-text-container">
              <v-autocomplete
                outlined
                label="Assignment Status"
                :items="statusOptions"
                v-model="assignmentStatus"
                :rules="bulkEditing ? [] : [rules.required]"
                :background-color="bulkEditing && this.newAssignmentDatas[0].assignmentDetails.assignmentStatus !== null ? '#E3F2FD' : ''"
              ></v-autocomplete>
              <p 
                class="position-absolute anounce-text"
                style="color: #1E88E5"
                v-if="bulkEditing && this.newAssignmentDatas[0].assignmentDetails.assignmentStatus !== null ? '#E3F2FD' : ''">
                Value was changed
              </p>
            </v-col>
            <v-col cols="12" sm="6" class="pb-0 anounce-text-container">
              <v-text-field outlined label="Link" v-model="link" :disabled="bulkQueryEdit" :filled="bulkQueryEdit"
                :background-color="bulkEditing && this.newAssignmentDatas[0].assignmentDetails.link !== null ? '#E3F2FD' : ''">
              </v-text-field>
              <p 
                class="position-absolute anounce-text"
                style="color: #1E88E5"
                v-if="bulkEditing && this.newAssignmentDatas[0].assignmentDetails.link !== null ? '#E3F2FD' : ''">
                Value was changed
              </p>
            </v-col>
          </v-row>
          <v-row 
            v-if="(relatedProject && relatedProject?.name === 'iTunes FC' && requestType === 'Localization') && !bulkQueryEdit" 
            class="align-center mt-0 mb-1">
            <v-col cols="12" class="d-flex py-0 anounce-text-container">
              <v-checkbox
                class="mt-0 pt-0"
                label="Apply the minimum rate"
                hide-details
                :indeterminate="bulkEditing && differentReviewedValues ? true : false"
                v-model="locMinimumRate"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row v-for="workType in workTypes" :key="workType.orderPosition">
            <v-col cols="12" sm="6" class="pb-0">
              <v-autocomplete
                outlined
                :label="`Work Type ${workType.orderPosition} Reference`"
                item-text="workTypeName"
                return-object
                :items="getWorkTypeRefs(workType.orderPosition)"
                :value="workType.customerPriceList"
                :rules="workType.orderPosition === 1 && !bulkEditing ? [rules.requiredObject] : []"
                :disabled="bulkQueryEdit"
                :filled="bulkQueryEdit"
                @input="updateField($event, 'customerPriceList', 'pricing', workType.orderPosition)"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="5" class="pb-0">
              <v-text-field
                outlined
                :label="`Work Type ${workType.orderPosition} Quantity`"
                type="number"
                :value="workType.quantity"
                :rules="
                  workType.orderPosition === 1 && !bulkEditing && newAssignmentDatas[0]?.assignmentDetails?.assignmentStatus !== 'Pending Hours' 
                    ? [rules.required, rules.negativeCheck]
                    : [rules.negativeCheck]
                "
                :disabled="bulkQueryEdit"
                :filled="bulkQueryEdit"
                @input="updateField($event, 'quantity', 'pricing', workType.orderPosition)"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="1" class="pb-0">
              <v-btn
                width="56px"
                min-width="56px"
                height="56px"
                class="elevation-2 px-0 mx-2"
                :color="workType.orderPosition === 1 ? 'accent' : 'secondary'"
                :disabled="bulkQueryEdit"
                :filled="bulkQueryEdit"
                @click="handleAdditionalWorkType(workType.orderPosition)"
              >
                <v-icon>mdi-{{ `${workType.orderPosition === 1 ? "plus" : "minus"}` }}</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="pb-0 anounce-text-container">
              <v-textarea
                outlined
                clearable
                rows="2"
                clear-icon="mdi-close-circle-outline"
                label="Related Text"
                v-model="relatedText"
                :disabled="bulkQueryEdit"
                :filled="bulkQueryEdit"
                :background-color="bulkEditing && this.newAssignmentDatas[0].assignmentDetails.relatedText !== null ? '#E3F2FD' : ''"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="pb-0 anounce-text-container">
              <v-textarea
                outlined
                clearable
                rows="2"
                clear-icon="mdi-close-circle-outline"
                label="Note to Staff"
                v-model="note"
                :disabled="bulkQueryEdit"
                :filled="bulkQueryEdit"
                :background-color="bulkEditing && this.newAssignmentDatas[0].assignmentDetails.note !== null ? '#E3F2FD' : ''"
              />
              <p 
                class="position-absolute anounce-text"
                style="color: #1E88E5"
                v-if="bulkEditing && this.newAssignmentDatas[0].assignmentDetails.note !== null ? '#E3F2FD' : ''">
                Value was changed
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="pb-0 anounce-text-container">
              <v-textarea
                outlined
                clearable
                rows="2"
                clear-icon="mdi-close-circle-outline"
                label="Note to Managers"
                v-model="noteToManager"
                :disabled="bulkQueryEdit"
                :filled="bulkQueryEdit"
                :background-color="bulkEditing && this.newAssignmentDatas[0].assignmentDetails.noteToManager !== null ? '#E3F2FD' : ''"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>
    </v-tabs-items>
  </v-form>
</template>

<script>
// vuex
import { mapActions, mapGetters } from "vuex";
// library
import _ from "lodash";
// internal
import { getWorkloadByEmployee } from "@/utils/quickbaseUtils";
import {
  getEnum,
  getCustomerPriceList,
  getEvents,
  getFile,
} from "@/utils/newDbUtils";
import { timeUnits } from "@/utils/mixins";
import { iTunesWorkTypeReference } from "@/utils/spreadsheetUploadUtils";

export default {
  name: "ADNewAssignmentItem",
  props: {
    datasIndex: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      tab: null,
      steps: ["General Info", "Staff Info", "Work Type Info"],
      requestDateMenu: false,
      dropdownsLoading: true,
      purchaseOrderNumber: "",
      clientDeliveryDateMenu: false,
      deliveryDateMenu: false,
      locMinimumRate: false,
      keywords: [],
      amAssignmentTitles: [
        "P1 Playlist Copy",
        "P3 Playlist Copy",
        "Classical Playlist Copy",
        "Playlist Title",
      ],
      fcAssignmentTitles: [
        "Album Review",
        "Arcade File",
        "Artist Bio",
        "Artist Interview Edit",
        "Artist Interview Prep",
        "Artist Interview Transcript",
        "Book Review",
        "Custom Tag",
        "Custom Title",
        "Discovery File",
        "Editorial Feature",
        "Guidelines",
        "Hero Blurb",
        "Hourly Localization Work",
        "Interview Note",
        "Marketing Copy",
        "MAS File",
        "Meeting",
        "Movie Description",
        "P1 Playlist Copy",
        "P3 Playlist Copy",
        "Storefront Description",
        "Today File",
      ],
      docLoading: false,
      docBlob: null,
      availabilityRecords: [],
      relatedWriters: [],
      writingDueDateMenu: false,
      writingDueDateAvailable: true,
      writingDueHoursAvailable: true,
      relatedEditors: [],
      editingDueDateMenu: false,
      editingDueDateAvailable: true,
      editingDueHoursAvailable: true,
      reviewers: [],
      reviewingDueDateMenu: false,
      reviewingDueDateAvailable: true,
      reviewingDueHoursAvailable: true,
      overrideStaff: false,
      loadWritingDueDate: false,
      override: false,
      dataForWriting: [],
      defaultWritingCapacity: [],
      adjustedWritingCapacity: [],
      dataForWritingCapacity: [],
      loadEditingDueDate: false,
      dataForEditing: [],
      defaultEditingCapacity: [],
      adjustedEditingCapacity: [],
      dataForEditingCapacity: [],
      errorForWritingCapacity: false,
      errorForEditingCapacity: false,
      payPeriodMenu: false,
      fileLocations: [],
      workTypeReferences: [],
      iTunesWorkType1ReferencesConditions: {
        Localization: iTunesWorkTypeReference,
        Adaptation: {
          "de-DE": {
            "de-CH": "ADP_04-00-09",
          },
          "en-US": {
            "en-AU": "ADP_04-00-06",
            "en-GB": "ADP_04-00-06",
            "en-CA": "ADP_04-00-06",
          },
        },
      },
      iTunesWorkType2References: ["CWR_1000-120-1500"],
      iTunesWorkType3References: ["CWR_1500-180-2800"],
      iTunesWorkType4References: [
        "CWR_2500-300-3800",
        "CWR_1000-380-3800",
        "CWR_1500-380-3800",
        "Playlist Long Copy < 400 (200) cc",
        "Playlist Extended Long Copy < 600 (200) cc",
      ],
      iTunesWorkType5References: ["CWR_5000-500-7000"],
      iTunesWorkType6References: ["CWR_300-100-500"],
      bulkEditingProject: null,
      conversionErrors: false,
      requestDateMenuTriggered: false,
      payPeriodMenuTriggered: false,
      clientDeliveryDateMenuTriggered: false,
      deliveryDateMenuTriggered: false,
      writingDueDateMenuTriggered: false,
      editingDueDateMenuTriggered: false,
      reviewingDueDateMenuTriggered: false,
      differentReviewedValues: false,
      differentFeedbackValues: false,
      differentBilledValues: false,
      valid: true,
      rules: {
        required: (value) => !!value || value === 0 || "Required",
        requiredObject: (value) =>
          (value ? !!Object.keys(value).length : !!value) || "Required",
        availabile: (specialist) =>
          !this[`${specialist}Unavailable`] ||
          `Unavailable on selected due date for ${specialist}.`,
        dateAvailabile: (operation) =>
          this[`${operation}DueDateAvailable`] ||
          "Selected date is after delivery date for the client.",
        timeAvailabile: (operation) =>
          this[`${operation}DueHoursAvailable`] ||
          "Selected time is after delivery due (time of day) for the client.",
        negativeCheck: (value) => value >= 0 || "Quantity can't be nagative.",
        isPDF: (value) =>
          value?.type == "application/pdf" ||
          value === null ||
          "Only PDF attachments are allowed.",
      },
    };
  },
  mixins: [timeUnits],
  computed: {
    ...mapGetters("assignment", [
      "newAssignmentDatas",
      "newAssignmentModalOpen",
      "geoOptions",
      "mediaTypeOptions",
      "requestTypeOptions",
      "projectsList",
      "statusOptions",
      "driList",
      "isEditMode",
      "bulkEditing",
      "bulkQueryEdit"
    ]),
    ...mapGetters("spreadsheetUpload", ["selectedDashboardRows"]),
    isCue() {
      return (
        this.relatedProject?.name !== "iTunes FC" &&
        this.relatedProject?.name !== "iTunes Music"
      );
    },
    firstItem() {
      return this.workTypes[0];
    },
    clientDeliveryDueHours() {
      return [...this.hoursList];
    },
    deliveryDueHoursList() {
      return [...this.hoursList];
    },
    formattedRequestDate: {
      get() {
        if (this.bulkEditing && !this.requestDateMenuTriggered) {
          return this.prepareItemToMassEdit({
            root: "schedule",
            item: "requestDate"
          })
        }
        return this.formatPickerDate(this.requestDate);
      },
      set(newVal) {
        if (this.bulkEditing && newVal === null) {
          return this.updateField("", "requestDate", "schedule");
        }
        this.requestDate = null;
      },
    },
    formattedPayPeriod: {
      get() {
        if (this.bulkEditing && !this.payPeriodMenuTriggered) {
          return this.prepareItemToMassEdit({
            root: "schedule",
            item: "payPeriod"
          })
        }
        return this.payPeriod
          ? `${this.$moment(this.payPeriod).format("MM-YYYY")}`
          : null;
      },
      set(newVal) {
        if (this.bulkEditing && newVal === null) {
          return this.updateField("", "payPeriod", "schedule");
        }
        this.payPeriod = null;
      },
    },
    formattedClientDeliveryDate: {
      get() {
        if (this.bulkEditing && !this.clientDeliveryDateMenuTriggered) {
          return this.prepareItemToMassEdit({
            root: "clientDeliveryDate"
          })
        }
        return this.formatPickerDate(this.clientDeliveryDate);
      },
      set(newVal) {
        if (this.bulkEditing && newVal === null) {
          return this.updateField("", "clientDeliveryDate");
        }
        this.clientDeliveryDate = null;
      },
    },
    formattedDeliveryDate: {
      get() {
        if (this.bulkEditing && !this.deliveryDateMenuTriggered) {
          return this.prepareItemToMassEdit({
            root: "schedule",
            item: "deliveryDate"
          })
        }
        return this.formatPickerDate(this.deliveryDate);
      },
      set(newVal) {
        if (this.bulkEditing && newVal === null) {
          return this.updateField("", "deliveryDate", "schedule");
        }
        this.deliveryDate = null;
      },
    },
    formattedWritingDueDate: {
      get() {
        if (this.bulkEditing && !this.writingDueDateMenuTriggered) {
          return this.prepareItemToMassEdit({
            root: "schedule",
            item: "primaryDueDate"
          })
        }
        return this.formatPickerDate(this.writingDueDate);
      },
      set(newVal) {
        if (this.bulkEditing && newVal === null) {
          return this.updateField("", "primaryDueDate", "schedule");
        }
        this.writingDueDate = null;
      },
    },
    formattedEditingDueDate: {
      get() {
        if (this.bulkEditing && !this.editingDueDateMenuTriggered) {
          return this.prepareItemToMassEdit({
            root: "schedule",
            item: "secondaryDueDate"
          })
        }
        return this.formatPickerDate(this.editingDueDate);
      },
      set(newVal) {
        if (this.bulkEditing && newVal === null) {
          return this.updateField("", "secondaryDueDate", "schedule");
        }
        this.editingDueDate = null;
      },
    },
    formattedReviewingDueDate: {
      get() {
        if (this.bulkEditing && !this.reviewingDueDateMenuTriggered) {
          return this.prepareItemToMassEdit({
            root: "schedule",
            item: "qcDueDate"
          })
        }
        return this.formatPickerDate(this.reviewingDueDate);
      },
      set(newVal) {
        if (this.bulkEditing && newVal === null) {
          return this.updateField("", "qcDueDate", "schedule");
        }
        this.reviewingDueDate = null;
      },
    },
    iTunesWorkType1References() {
      return this.requestType === "Copywriting"
        ? [
            "CWR_500-60-1100",
            "CWR_2500-00-4500",
            "CWR_3500-00-4500",
            "CWR_1000-00-00",
          ]
        : this.iTunesWorkType1ReferencesConditions[this.requestType]?.[
            this.sourceGeo
          ]?.[this.geo]
        ? [
            this.iTunesWorkType1ReferencesConditions[this.requestType][
              this.sourceGeo
            ][this.geo],
          ].concat(["LOC_2500-00-3800", "LOC_2500-00-4500", "LOC_3500-00-4500", "LOC_300-100-700", "LOC_13-02-26"])
        : this.workTypeReferences.map((el) => el.workTypeName);
    },
    relatedProject: {
      get() {
        return this.getStartingValues("project");
      },
      set(newVal) {
        return this.updateField(newVal, "project");
      },
    },
    projectManager: {
      get() {
        if (this.bulkEditing) {
         return  this.prepareItemToMassEdit({
           root: "projectManager",
           item: "id"
         })
        }
        return this.getStartingValues("projectManager");
      },
      set(newVal) {
        if (this.bulkEditing) {
          if (newVal === this.prepareItemToMassEdit({
            root: "projectManager",
            item: "id"
          })) {
            return this.updateField(null, "projectManager");
          } else if (newVal === null) {
            return this.updateField("", "projectManager");
          }
        }
        return this.updateField(newVal, "projectManager");
      },
    },
    requestDate: {
      get() {
        return this.getStartingValues("requestDate", "schedule");
      },
      set(newVal) {
        return this.updateField(newVal, "requestDate", "schedule");
      },
    },
    emailSubjectLine: {
      get() {
        if (this.bulkEditing) {
          return this.prepareItemToMassEdit({
            root: "assignmentDetails",
            item: "emailSubjectLine"
          });
        }
        return this.getStartingValues("emailSubjectLine", "assignmentDetails");
      },
      set(newVal) {
        if (this.bulkEditing) {
          if (newVal === this.prepareItemToMassEdit({
            root: "assignmentDetails",
            item: "emailSubjectLine"
          })) {
            return this.updateField(null, "emailSubjectLine", "assignmentDetails");
          } else if (newVal === null) {
            return this.updateField("", "emailSubjectLine", "assignmentDetails");
          }
        }
        return this.updateField(
          newVal,
          "emailSubjectLine",
          "assignmentDetails"
        );
      },
    },
    clientRequester: {
      get() {
        if (this.bulkEditing) {
         return  this.prepareItemToMassEdit({
           root: "clientRequester",
           item: "id"
         })
        }
        return this.getStartingValues("clientRequester");
      },
      set(newVal) {
        if (this.bulkEditing) {
          if (newVal?.user?.id === this.prepareItemToMassEdit({
            root: "clientRequester",
            item: "id"
          })?.user?.id) {
            return this.updateField(null, "clientRequester");
          } else if (newVal === null) {
            return this.updateField("", "clientRequester");
          }
        }
        return this.updateField(newVal, "clientRequester");
      },
    },
    requestType: {
      get() {
        if (this.bulkEditing) {
          return this.prepareItemToMassEdit({
            root: "assignmentDetails",
            item: "requestType"
          });
        }
        return this.getStartingValues("requestType", "assignmentDetails");
      },
      set(newVal) {
        if (this.bulkEditing) {
          if (newVal === this.prepareItemToMassEdit({
            root: "assignmentDetails",
            item: "requestType"
          })) {
            return this.updateField(null, "requestType", "assignmentDetails");
          } else if (newVal === null) {
            return this.updateField("", "requestType", "assignmentDetails");
          }
        }
        return this.updateField(newVal, "requestType", "assignmentDetails");
      },
    },
    mediaType: {
      get() {
        if (this.bulkEditing) {
          return this.prepareItemToMassEdit({
            root: "assignmentDetails",
            item: "mediaType"
          })
        }
        return this.getStartingValues("mediaType", "assignmentDetails");
      },
      set(newVal) {
        if (this.bulkEditing) {
          if (newVal === this.prepareItemToMassEdit({
            root: "assignmentDetails",
            item: "mediaType"
          })) {
            return this.updateField(null, "mediaType", "assignmentDetails");
          } else if (newVal === null) {
            return this.updateField("", "mediaType", "assignmentDetails");
          }
        }
        return this.updateField(newVal, "mediaType", "assignmentDetails");
      },
    },
    keyword: {
      get() {
        if (this.bulkEditing) {
          return this.prepareItemToMassEdit({
            root: "assignmentDetails",
            item: "keyword"
          })
        }
        return this.getStartingValues("keyword", "assignmentDetails");
      },
      set(newVal) {
        if (this.bulkEditing) {
          if (newVal === this.prepareItemToMassEdit({
            root: "assignmentDetails",
            item: "keyword"
          })) {
            return this.updateField(null, "keyword", "assignmentDetails");
          } else if (newVal === null) {
            return this.updateField("", "keyword", "assignmentDetails");
          }
        }
        return this.updateField(newVal, "keyword", "assignmentDetails");
      },
    },
    clientDeliveryDate: {
      get() {
        return this.getStartingValues("clientDeliveryDate");
      },
      set(newVal) {
        return this.updateField(newVal, "clientDeliveryDate");
      },
    },
    deliveryDate: {
      get() {
        return this.getStartingValues("deliveryDate", "schedule");
      },
      set(newVal) {
        this.updateField(newVal, "deliveryDate", "schedule");
      },
    },
    clientDeliveryDue: {
      get() {
        if (this.bulkEditing) {
          return this.prepareItemToMassEdit({
            root: "clientDeliveryDue",
          })
        }
        return this.getStartingValues("clientDeliveryDue");
      },
      set(newVal) {
        if (this.bulkEditing) {
          if (newVal === this.prepareItemToMassEdit({
            root: "clientDeliveryDue",
          })) {
            return this.updateField(null, "clientDeliveryDue");
          } else if (newVal === null) {
            return this.updateField("", "clientDeliveryDue");
          }
        }
        return this.updateField(newVal, "clientDeliveryDue");
      },
    },
    deliveryDue: {
      get() {
        if (this.bulkEditing) {
          return this.prepareItemToMassEdit({
            root: "schedule",
            item: "deliveryTime"
          })
        }
        return this.getStartingValues("deliveryTime", "schedule");
      },
      set(newVal) {
        if (this.bulkEditing) {
          if (newVal === this.prepareItemToMassEdit({
            root: "schedule",
            item: "deliveryTime"
          })) {
            return this.updateField(null, "deliveryTime", "schedule");
          } else if (newVal === null) {
            return this.updateField("", "deliveryTime", "schedule");
          }
        }
        this.updateField(newVal, "deliveryTime", "schedule");
      },
    },
    timeZone: {
      get() {
        return this.getStartingValues("timeZone");
      },
      set(newVal) {
        return this.updateField(newVal, "timeZone");
      },
    },
    urgent: {
      get() {
        return this.getStartingValues("urgent", "assignmentDetails");
      },
      set(newVal) {
        this.updateField(newVal, "urgent", "assignmentDetails");
      },
    },
    sourceGeo: {
      get() {
        if (this.bulkEditing) {
          return this.prepareItemToMassEdit({
            root: "assignmentDetails",
            item: "sourceGeo"
          })
        }
        return this.getStartingValues("sourceGeo", "assignmentDetails");
      },
      set(newVal) {
        if (this.bulkEditing) {
          if (newVal === this.prepareItemToMassEdit({
            root: "assignmentDetails",
            item: "sourceGeo"
          })) {
            return this.updateField(null, "sourceGeo", "assignmentDetails");
          } else if (newVal === null) {
            return this.updateField("", "sourceGeo", "assignmentDetails");
          }
        }
        return this.updateField(newVal, "sourceGeo", "assignmentDetails");
      },
    },
    geo: {
      get() {
        if (this.bulkEditing) {
          return this.prepareItemToMassEdit({
            root: "assignmentDetails",
            item: "geo"
          })
        }
        return this.getStartingValues("geo", "assignmentDetails");
      },
      set(newVal) {
        if (this.bulkEditing) {
          if (newVal === this.prepareItemToMassEdit({
            root: "assignmentDetails",
            item: "geo"
          })) {
            return this.updateField(null, "geo", "assignmentDetails");
          } else if (newVal === null) {
            return this.updateField("", "geo", "assignmentDetails");
          }
        }
        return this.updateField(newVal, "geo", "assignmentDetails");
      },
    },
    po: {
      get() {
        if (this.bulkEditing && this.bulkEditingProject) {
          return this.prepareItemToMassEdit({
            root: "pricing",
            item: "purchaseOrder"
          })
        }
        if (this.getStartingValues("purchaseOrder", "pricing") && this.relatedPurchaseOrders && this.relatedPurchaseOrders.length) {
          const po = this.getStartingValues("purchaseOrder", "pricing");
          const poFromList = this.relatedPurchaseOrders.find((item) => item.purchaseOrderNumber === po.purchaseOrderNumber);
          if (!poFromList) {
            this.relatedPurchaseOrders.push(po);
            po.purchaseOrderNumber = "Not valid value";
            return po;
          }
        }
        return this.getStartingValues("purchaseOrder", "pricing");
      },
      set(newVal) {
        if (this.bulkEditing) {
          if (newVal === this.prepareItemToMassEdit({
            root: "pricing",
            item: "purchaseOrder"
          })) {
            return this.updateField(null, "purchaseOrder", "pricing");
          } else if (newVal === null) {
            return this.updateField(-1, "purchaseOrder", "pricing");
          }
        }
        return this.updateField(newVal, "purchaseOrder", "pricing");
      },
    },
    wordCount: {
      get() {
        if (this.bulkEditing) {
          return 0;
        }
        return this.getStartingValues("wordCount", "assignmentDetails");
      },
      set(newVal) {
        return this.updateField(newVal, "wordCount", "assignmentDetails");
      },
    },
    characterCount: {
      get() {
        if (this.bulkEditing) {
          return 0;
        }
        return this.getStartingValues("characterCount", "assignmentDetails");
      },
      set(newVal) {
        return this.updateField(newVal, "characterCount", "assignmentDetails");
      },
    },
    taskID: {
      get() {
        return this.getStartingValues("taskId", "task");
      },
      set(newVal) {
        this.updateField(newVal, "taskId", "task");
        return this.updateField(newVal, "taskId", "assignmentDetails");
      },
    },
    assignmentID: {
      get() {
        return this.getStartingValues("assignmentId", "assignmentDetails");
      },
      set(newVal) {
        return this.updateField(newVal, "assignmentId", "assignmentDetails");
      },
    },
    assignmentDetails: {
      get() {
        if (this.bulkEditing) {
          return this.prepareItemToMassEdit({
            root: "assignmentDetails",
            item: "assignmentDetail",
          })
        }
        return this.getStartingValues("assignmentDetail", "assignmentDetails");
      },
      set(newVal) {
        return this.updateField(
          newVal,
          "assignmentDetail",
          "assignmentDetails"
        );
      },
    },
    assignmentTitle: {
      get() {
        if (this.bulkEditing) {
          return this.prepareItemToMassEdit({
            root: "assignmentDetails",
            item: "assignmentTitle",
          })
        }
        return this.getStartingValues("assignmentTitle", "assignmentDetails");
      },
      set(newVal) {
        return this.updateField(newVal, "assignmentTitle", "assignmentDetails");
      },
    },
    assignmentStatus: {
      get() {
        if (this.bulkEditing) {
          return this.prepareItemToMassEdit({
            root: "assignmentDetails",
            item: "assignmentStatus",
          })
        }
        return this.getStartingValues("assignmentStatus", "assignmentDetails");
      },
      set(newVal) {
        return this.updateField(
          newVal,
          "assignmentStatus",
          "assignmentDetails"
        );
      },
    },
    genre: {
      get() {
        if (this.bulkEditing) {
          return this.prepareItemToMassEdit({
            root: "assignmentDetails",
            item: "genre",
          })
        }
        return this.getStartingValues("genre", "assignmentDetails");
      },
      set(newVal) {
        return this.updateField(newVal, "genre", "assignmentDetails");
      },
    },
    writer: {
      get() {
        if (this.bulkEditing) {
          return this.prepareItemToMassEdit({
            root: "primary",
            item: "id",
          })
        }
        return this.getStartingValues("primary");
      },
      set(newVal) {
        return this.updateField(newVal, "primary");
      },
    },
    writingDueDate: {
      get() {
        return this.getStartingValues("primaryDueDate", "schedule");
      },
      set(newVal) {
        this.updateField(newVal, "primaryDueDate", "schedule");
      },
    },
    writingDue: {
      get() {
        if (this.bulkEditing) {
          return this.prepareItemToMassEdit({
            root: "schedule",
            item: "primaryDueTime",
          })
        }
        return this.getStartingValues("primaryDueTime", "schedule");
      },
      set(newVal) {
        if (this.bulkEditing) {
          if (newVal === this.prepareItemToMassEdit({
            root: "schedule",
            item: "primaryDueTime"
          })) {
            return this.updateField(null, "primaryDueTime", "schedule");
          } else if (newVal === null) {
            return this.updateField("", "primaryDueTime", "schedule");
          }
        }
        this.updateField(newVal, "primaryDueTime", "schedule");
      },
    },
    editor: {
      get() {
        if (this.bulkEditing) {
          return this.prepareItemToMassEdit({
            root: "secondary",
            item: "id",
          })
        }
        return this.getStartingValues("secondary");
      },
      set(newVal) {
        return this.updateField(newVal, "secondary");
      },
    },
    editingDueDate: {
      get() {
        return this.getStartingValues("secondaryDueDate", "schedule");
      },
      set(newVal) {
        this.updateField(newVal, "secondaryDueDate", "schedule");
      },
    },
    editingDue: {
      get() {
        if (this.bulkEditing) {
          return this.prepareItemToMassEdit({
            root: "schedule",
            item: "secondaryDueTime",
          })
        }
        return this.getStartingValues("secondaryDueTime", "schedule");
      },
      set(newVal) {
        if (this.bulkEditing) {
          if (newVal === this.prepareItemToMassEdit({
            root: "schedule",
            item: "secondaryDueTime"
          })) {
            return this.updateField(null, "secondaryDueTime", "schedule");
          } else if (newVal === null) {
            return this.updateField("", "secondaryDueTime", "schedule");
          }
        }
        this.updateField(newVal, "secondaryDueTime", "schedule");
      },
    },
    reviewer: {
      get() {
        if (this.bulkEditing) {
          return this.prepareItemToMassEdit({
            root: "qc",
            item: "id",
          })
        }
        return this.getStartingValues("qc");
      },
      set(newVal) {
        return this.updateField(newVal, "qc");
      },
    },
    reviewingDueDate: {
      get() {
        return this.getStartingValues("qcDueDate", "schedule");
      },
      set(newVal) {
        this.updateField(newVal, "qcDueDate", "schedule");
      },
    },
    reviewingDue: {
      get() {
        if (this.bulkEditing) {
          return this.prepareItemToMassEdit({
            root: "schedule",
            item: "qcDueTime",
          })
        }
        return this.getStartingValues("qcDueTime", "schedule");
      },
      set(newVal) {
        if (this.bulkEditing) {
          if (newVal === this.prepareItemToMassEdit({
            root: "schedule",
            item: "qcDueTime"
          })) {
            return this.updateField(null, "secondaryDueTime", "schedule");
          } else if (newVal === null) {
            return this.updateField("", "secondaryDueTime", "schedule");
          }
        }
        this.updateField(newVal, "qcDueTime", "schedule");
      },
    },
    payPeriod: {
      get() {
        return this.getStartingValues("payPeriod", "schedule");
      },
      set(newVal) {
        this.updateField(newVal, "payPeriod", "schedule");
      },
    },
    fileLocation: {
      get() {
        if (this.bulkEditing) {
          return this.prepareItemToMassEdit({
            root: "assignmentDetails",
            item: "fileLocation",
          })
        }
        return this.getStartingValues("fileLocation", "assignmentDetails");
      },
      set(newVal) {
        return this.updateField(newVal, "fileLocation", "assignmentDetails");
      },
    },
    link: {
      get() {
        if (this.bulkEditing) {
          return this.prepareItemToMassEdit({
            root: "assignmentDetails",
            item: "link",
          })
        }
        return this.getStartingValues("link", "assignmentDetails");
      },
      set(newVal) {
        return this.updateField(newVal, "link", "assignmentDetails");
      },
    },
    workTypes: {
      get() {
        return !!this.getStartingValues("workTypes", "pricing")?.length
          ? this.getStartingValues("workTypes", "pricing")
          : this.updateField(
              [{ customerPriceList: null, quantity: null, orderPosition: 1 }],
              "workTypes",
              "pricing"
            );
      },
      set(newVal) {
        return this.updateField(newVal, "workTypes", "pricing");
      },
    },
    note: {
      get() {
        if (this.bulkEditing) {
          return this.prepareItemToMassEdit({
            root: "assignmentDetails",
            item: "note",
          })
        }
        return this.getStartingValues("note", "assignmentDetails");
      },
      set(newVal) {
        return this.updateField(newVal, "note", "assignmentDetails");
      },
    },
    noteToManager: {
      get() {
        if (this.bulkEditing) {
          return this.prepareItemToMassEdit({
            root: "assignmentDetails",
            item: "noteToManager",
          })
        }
        return this.getStartingValues("noteToManager", "assignmentDetails");
      },
      set(newVal) {
        return this.updateField(newVal, "noteToManager", "assignmentDetails");
      },
    },
    relatedText: {
      get() {
        if (this.bulkEditing) {
          return this.prepareItemToMassEdit({
            root: "assignmentDetails",
            item: "relatedText",
          })
        }
        return this.getStartingValues("relatedText", "assignmentDetails");
      },
      set(newVal) {
        return this.updateField(newVal, "relatedText", "assignmentDetails");
      },
    },
    clientReviewed: {
      get() {
        if (this.bulkEditing) {
          let value = this.prepareItemToMassEdit({
            root: "assignmentDetails",
            item: "clientReviewed",
          });
          if (value && typeof value === "string" && value.includes("Different")) {
            this.differentReviewedValues = true;
            return;
          }
          if (value && typeof value === "string" && value.includes("No value")) {
            this.differentReviewedValues = false;
            return false;
          }
          return value;
        }
        return this.getStartingValues("clientReviewed", "assignmentDetails");
      },
      set(newVal) {
        if (this.bulkEditing) {
          this.differentReviewedValues = false;
        }
        return this.updateField(newVal, "clientReviewed", "assignmentDetails");
      },
    },
    clientFeedback: {
      get() {
        if (this.bulkEditing) {
          let value = this.prepareItemToMassEdit({
            root: "assignmentDetails",
            item: "clientFeedback",
          });
          if (value && typeof value === "string" && value.includes("Different")) {
            this.differentFeedbackValues = true;
            return;
          }
          if (value && typeof value === "string" && value.includes("No value")) {
            this.differentFeedbackValues = false;
            return false;
          }
          return value;
        }
        return this.getStartingValues("clientFeedback", "assignmentDetails");
      },
      set(newVal) {
        if (this.bulkEditing) {
          this.differentFeedbackValues = false;
        }
        return this.updateField(newVal, "clientFeedback", "assignmentDetails");
      },
    },
    billed: {
      get() {
        if (this.bulkEditing) {
          let value = this.prepareItemToMassEdit({
            root: "assignmentDetails",
            item: "billed",
          });
          if (value && typeof value === "string" && value.includes("Different")) {
            this.differentBilledValues = true;
            return;
          }
          if (value && typeof value === "string" && value.includes("No value")) {
            this.differentBilledValues = false;
            return false;
          }
          return value;
        }
        return this.getStartingValues("billed", "assignmentDetails");
      },
      set(newVal) {
        if (this.bulkEditing) {
          this.differentBilledValues = false;
        }
        return this.updateField(newVal, "billed", "assignmentDetails");
      },
    },
    document: {
      get() {
        return this.getStartingValues("document");
      },
      set(newVal) {
        return this.updateField(newVal, "document");
      },
    },
    writingDueHours() {
      return this.handleDueHoursAvailability("writing");
    },
    editingDueHours() {
      return this.handleDueHoursAvailability("editing");
    },
    reviewingDueHours() {
      return this.handleDueHoursAvailability("reviewing");
    },
    assignmentTitles() {
      if (this.relatedProject?.name === "iTunes FC")
        return this.fcAssignmentTitles;
      if (this.relatedProject?.name === "iTunes Music")
        return this.amAssignmentTitles;
      else
        return [...this.amAssignmentTitles, ...this.fcAssignmentTitles].sort();
    },
    itemToEdit() {
      return this.newAssignmentDatas[0]?.id;
    },
    sourceGeos() {
      return [ ...this.geoOptions ]
    },
    mainGeoOptions() {
      return [ ...this.geoOptions ]
    },
    relatedPurchaseOrders() {
      if (this.isEditMode) {
        return this.relatedProject?.purchaseOrders;
      }
      return (
        this.bulkEditing ? this.bulkEditingProject : this.relatedProject
      )?.purchaseOrders?.filter((el) => el.isActive);
    },
    clientRequesters() {
      return (
        this.bulkEditing ? this.bulkEditingProject : this.relatedProject
      )?.clientRequesters
        ?.filter((el) => el.active)
        ?.sort((a, b) => a?.user?.firstName?.localeCompare(b?.user?.firstName));
    },
    bulkEditingProjectName() {
      return this.bulkEditingProject?.name;
    },
  },
  watch: {
    'firstItem.quantity': {
      handler(val) {
        if (
          this.requestType === "Localization" &&
          this.workTypes[1]?.customerPriceList?.workTypeName === "LOC_300-100-700"
        ) {
          this.wordCount = val;
        }
      }
    },
    isEditMode() {
      this.locMinimumRate = false;
    },
    requestDateMenu() {
      if (this.requestDateMenu) {
        this.requestDateMenuTriggered = true;
      }
      if (!this.requestDateMenu && !this.requestDate) {
        this.requestDateMenuTriggered = false;
      }
    },
    payPeriodMenu() {
      if (this.payPeriodMenu) {
        this.payPeriodMenuTriggered = true;
      }
      if (!this.payPeriodMenu && !this.payPeriod) {
        this.payPeriodMenuTriggered = false;
      }
    },
    clientDeliveryDateMenu() {
      if (this.clientDeliveryDateMenu) {
        this.clientDeliveryDateMenuTriggered = true;
      }
      if (!this.clientDeliveryDateMenu && !this.clientDeliveryDate) {
        this.clientDeliveryDateMenuTriggered = false;
      }
    },
    deliveryDateMenu() {
      if (this.deliveryDateMenu) {
        this.deliveryDateMenuTriggered = true;
      }
      if (!this.deliveryDateMenu && !this.deliveryDate) {
        this.deliveryDateMenuTriggered = false;
      }
    },
    writingDueDateMenu() {
      if (this.writingDueDateMenu) {
        this.writingDueDateMenuTriggered = true;
      }
      if (!this.writingDueDateMenu && !this.writingDueDate) {
        this.writingDueDateMenuTriggered = false;
      }
    },
    editingDueDateMenu() {
      if (this.editingDueDateMenu) {
        this.editingDueDateMenuTriggered = true;
      }
      if (!this.editingDueDateMenu && !this.editingDueDate) {
        this.editingDueDateMenuTriggered = false;
      }
    },
    reviewingDueDateMenu() {
      if (this.reviewingDueDateMenu) {
        this.reviewingDueDateMenuTriggered = true;
      }
      if (!this.reviewingDueDateMenu && !this.reviewingDueDate) {
        this.reviewingDueDateMenuTriggered = false;
      }
    },
    itemToEdit() {
      this.handleEditMode();
    },
    writingDueHours(newVal) {
      this.handleDueHoursValidation("writing", newVal);
    },
    editingDueHours(newVal) {
      this.handleDueHoursValidation("editing", newVal);
    },
    reviewingDueHours(newVal) {
      this.handleDueHoursValidation("reviewing", newVal);
    },
    override() {
      this.handleStaffDuesValidation();
    },
    overrideStaff() {
      if (this.editor?.user?.id)
        this.handleStaffAvailability("relatedEditors", "editing", "editor");
      if (this.writer?.user?.id)
        this.handleStaffAvailability("relatedWriters", "writing", "writer");
      if (this.reviewer?.user?.id)
        this.handleStaffAvailability("reviewers", "reviewing", "reviewer");
    },
    deliveryDate(newVal) {
      if (!this.bulkEditing) {
        this.handleStaffDuesValidation();
        this.urgent = this.$moment(newVal).isSameOrBefore(
          this.$moment(this.requestDate).add(2, "days")
        );
      }
    },
    wordCount() {
      this.handleCapacityCalculation("Writing");
      this.handleCapacityCalculation("Editing");
    },
    writingDueDate() {
      this.handleCapacityCalculation("Writing");
    },
    editingDueDate() {
      this.handleCapacityCalculation("Editing");
    },
    newAssignmentModalOpen(newVal) {
      if (newVal) this.$refs.form.resetValidation();
    },
    bulkEditing() {
      this.handleBulkEditingMode();
    },
    locMinimumRate() {
      if (this.locMinimumRate) {
        const wtList = this.getWorkTypeRefs(1);
        const wt = wtList.find((el) => el.workTypeName === "LOC_300-100-700");
        this.updateField(wt, 'customerPriceList', 'pricing', 1);
      } else {
        this.updateField(null, 'customerPriceList', 'pricing', 1);
        this.updateField(0, 'quantity', 'pricing', 1);
      }
    }
  },
  mounted() {
    this.handleEditMode();
    this.handleBulkEditingMode();
    this.handleDropdowns();
    this.getAvailabilityData();
  },
  unmounted() {
    this.requestDateMenuTriggered = false;
  },
  methods: {
    ...mapActions("assignment", [
      "setNewAssignmentDatas",
      "deleteNewAssignmentDatas",
      "setAssignmentsDeepClone",
      "setJumpOverPPCheck"
    ]),
    prepareItemToMassEdit({ root, item }) {
      if (this.bulkEditing && !this.bulkQueryEdit) {
        const detailsValues = item === "purchaseOrder" 
          ? this.selectedDashboardRows.map(obj => obj[root] && obj[root][item] && obj[root][item].purchaseOrderNumber)
          : this.selectedDashboardRows.map(obj => obj[root] && obj[root][item]);
        const allEqual = detailsValues.every(value => value === detailsValues[0]);
        if (allEqual && detailsValues.some(element => element !== null && element !== undefined)) {
          const firstNonNullElement = detailsValues.find(element => element !== null && element !== undefined);
          if (root === "clientRequester" && this.clientRequesters) {
            return this.clientRequesters.find(el => el.user?.id === firstNonNullElement) ? this.clientRequesters.find(el => el.user?.id === firstNonNullElement) : `No value`;
          }
          if (root === "primary") {
            this.relatedWriters.push({user: this.selectedDashboardRows[0].primary});
            return {user: this.selectedDashboardRows[0].primary};
          }
          if (root === "secondary") {
            this.relatedEditors.push({user: this.selectedDashboardRows[0].secondary});
            return {user: this.selectedDashboardRows[0].secondary};
          }
          if (root === "qc") {
            this.reviewers.push({user: this.selectedDashboardRows[0].qc});
            return {user: this.selectedDashboardRows[0].qc};
          }
          if (root === "projectManager") {
            return this.driList.find(el => el.user?.id === firstNonNullElement) ? this.driList.find(el => el.user?.id === firstNonNullElement) : `No value`;
          }
          return firstNonNullElement;
        } else if (!detailsValues.some(element => element !== null && element !== undefined)) {
          const uniqueValues = new Set(detailsValues);
          if (root === "primary" && uniqueValues.size > 1) {
            this.relatedWriters.push(`Different values (${uniqueValues.size})`);
            return `Different values (${uniqueValues.size})`;
          } else if (root === "primary" && uniqueValues.size === 1) {
            this.relatedWriters.push(`No value`);
            return `No value`;
          }
          if (root === "secondary" && uniqueValues.size > 1) {
            this.relatedEditors.push(`Different values (${uniqueValues.size})`);
            return `Different values (${uniqueValues.size})`;
          } else if (root === "secondary" && uniqueValues.size === 1) {
            this.relatedEditors.push(`No value`);
            return `No value`;
          }
          if (root === "qc" && uniqueValues.size > 1) {
            this.reviewers.push(`Different values (${uniqueValues.size})`);
            return `Different values (${uniqueValues.size})`;
          } else if (root === "qc" && uniqueValues.size === 1) {
            this.reviewers.push(`No value`);
            return `No value`;
          }
          if (root === "clientDeliveryDue" && uniqueValues.size > 1) {
            this.clientDeliveryDueHours.push(`Different values (${uniqueValues.size})`);
            return `Different values (${uniqueValues.size})`;
          } else if (root === "clientDeliveryDue" && uniqueValues.size === 1) {
            this.clientDeliveryDueHours.push(`No value`);
            return `No value`;
          }
          if (root === "schedule" && item === "secondaryDueTime" && uniqueValues.size > 1) {
            this.editingDueHours.push(`Different values (${uniqueValues.size})`);
            return `Different values (${uniqueValues.size})`;
          } else if (root === "schedule" && item === "secondaryDueTime" && uniqueValues.size === 1) {
            this.editingDueHours.push(`No value`);
          }
          if (root === "schedule" && item === "qcDueTime" && uniqueValues.size > 1) {
            this.reviewingDueHours.push(`Different values (${uniqueValues.size})`);
            return `Different values (${uniqueValues.size})`;
          } else if (root === "schedule" && item === "qcDueTime" && uniqueValues.size === 1) {
            this.reviewingDueHours.push(`No value`);
          }
          return `No value`;
        } else {
          const uniqueValues = new Set(detailsValues);
          if (root === "clientRequester") {
            const defaultUser = {
              user: {
                id: 10000000,
                firstName: "Different",
                lastName: `values (${uniqueValues.size})`,
              }
            };
            this.clientRequesters.push(defaultUser)
            return defaultUser;
          }
          if (root === "assignmentDetails" && item === "requestType") {
            this.requestTypeOptions.push(`Different values (${uniqueValues.size})`);
          }
          if (root === "assignmentDetails" && item === "mediaType") {
            this.mediaTypeOptions.push(`Different values (${uniqueValues.size})`);
          }
          if (root === "assignmentDetails" && item === "keyword") {
            this.keywords.push(`Different values (${uniqueValues.size})`);
          }
          if (root === "assignmentDetails" && item === "assignmentTitle") {
            this.assignmentTitles.push(`Different values (${uniqueValues.size})`);
          }
          if (root === "assignmentDetails" && item === "sourceGeo") {
            this.sourceGeos.push(`Different values (${uniqueValues.size})`);
          }
          if (root === "assignmentDetails" && item === "geo") {
            this.mainGeoOptions.push(`Different values (${uniqueValues.size})`);
          }
          if (root === "pricing" && item === "purchaseOrder") {
            this.relatedPurchaseOrders.push({purchaseOrderNumber: `Different values (${uniqueValues.size})`});
          }
          if (root === "primary" && item === "id") {
            this.relatedWriters.push(`Different values (${uniqueValues.size})`);
          }
          if (root === "secondary" && item === "id") {
            this.relatedEditors.push(`Different values (${uniqueValues.size})`);
          }
          if (root === "reviewer" && item === "id") {
            this.reviewers.push(`Different values (${uniqueValues.size})`);
          }
          if (root === "assignmentDetails" && item === "fileLocation") {
            this.fileLocations.push(`Different values (${uniqueValues.size})`);
          }
          if (root === "assignmentDetails" && item === "assignmentStatus") {
            this.statusOptions.push(`Different values (${uniqueValues.size})`);
          }
          if (root === "projectManager" && item === "id") {
            this.driList.push(`Different values (${uniqueValues.size})`);
          }
          if (root === "schedule" && item === "deliveryTime") {
            this.deliveryDueHoursList.push(`Different values (${uniqueValues.size})`);
          }
          if (root === "schedule" && item === "primaryDueTime") {
            this.writingDueHours.push(`Different values (${uniqueValues.size})`);
          }
          if (root === "schedule" && item === "secondaryDueTime") {
            this.editingDueHours.push(`Different values (${uniqueValues.size})`);
          }
          if (root === "schedule" && item === "qcDueTime") {
            this.reviewingDueHours.push(`Different values (${uniqueValues.size})`);
          }
          return `Different values (${uniqueValues.size})`;
        }
      } else if (this.bulkEditing && this.bulkQueryEdit) {
        const detailsValues = item === "purchaseOrder" 
          ? this.selectedDashboardRows.map(obj => obj[root] && obj[root][item] && obj[root][item].purchaseOrderNumber)
          : this.selectedDashboardRows.map(obj => obj[root] && obj[root][item]);
          const uniqueValues = new Set(detailsValues);
          if (root === "clientRequester") {
            const defaultUser = {
              user: {
                id: 10000000,
                firstName: "Different",
                lastName: `values (10+)`,
              }
            };
            this.clientRequesters.push(defaultUser)
            return defaultUser;
          }
          if (root === "assignmentDetails" && item === "requestType") {
            this.requestTypeOptions.push(`Different values (10+)`);
          }
          if (root === "assignmentDetails" && item === "mediaType") {
            this.mediaTypeOptions.push(`Different values (10+)`);
          }
          if (root === "assignmentDetails" && item === "keyword") {
            this.keywords.push(`Different values (10+)`);
          }
          if (root === "assignmentDetails" && item === "assignmentTitle") {
            this.assignmentTitles.push(`Different values (${uniqueValues.size})`);
          }
          if (root === "assignmentDetails" && item === "geo") {
            this.mainGeoOptions.push(`Different values (10+)`);
          }
          if (root === "pricing" && item === "purchaseOrder") {
            this.relatedPurchaseOrders.push({purchaseOrderNumber: `Different values (5+)`});
            return `Different values (5+)`;
          }
          if (root === "assignmentDetails" && item === "fileLocation") {
            this.fileLocations.push(`Different values (3+)`);
            return `Different values (3+)`;
          }
          if (root === "assignmentDetails" && item === "assignmentStatus") {
            this.statusOptions.push(`Different values (${uniqueValues.size})`);
          }
          return `Different values (10+)`;
      }
    },
    handleEditMode() {
      if (this.isEditMode) {
        if (this.document) this.handleDoc();
        this.relatedProject = this.projectsList.find(
          (el) => el.name === this.newAssignmentDatas[0].project?.name
        );
        this.updateRelatedProjectSmartFields(this.relatedProject, true);
        this.setAssignmentsDeepClone(_.cloneDeep(this.newAssignmentDatas));
        this.setJumpOverPPCheck(true);
      } else {
        this.docBlob = null;
        if (this.geo) this.getStaffByGeo(this.geo);
      }
      this.overrideStaff = this.override = false;
    },
    updateField(value, field, dto, workTypeIndex) {
      const newPayload = _.cloneDeep(this.newAssignmentDatas);
      const indexCopy = _.cloneDeep(newPayload[this.datasIndex]);
      if (workTypeIndex !== undefined) {
        indexCopy[dto].workTypes.find(el => el.orderPosition === workTypeIndex)[field] = value;
      }
      else if (dto) indexCopy[dto][field] = value;
      else indexCopy[field] = value;
      newPayload[this.datasIndex] = indexCopy;
      this.setNewAssignmentDatas(newPayload);
    },
    getStartingValues(field, dto) {
      if (dto) return this.newAssignmentDatas[this.datasIndex][dto][field];
      else return this.newAssignmentDatas[this.datasIndex][field];
    },
    updateRelatedProjectSmartFields(selectedProject, itemToEditHandling) {
      if (selectedProject) {
        this.po = itemToEditHandling
          ? this.newAssignmentDatas[0].pricing.purchaseOrder ?? null
          : selectedProject.purchaseOrders?.find((el) => el.isActive) ?? null;
        if (itemToEditHandling)
          this.clientRequester = this.clientRequesters.find(
            (el) =>
              el?.user?.id === this.newAssignmentDatas[0].clientRequester?.id
          );
        else
          this.clientRequester = this.requestType = this.assignmentTitle = null;
        this.getStaffByGeo(this.geo, itemToEditHandling);
      } else {
        if (!itemToEditHandling)
          this.po =
            this.clientRequester =
            this.requestType =
            this.assignmentTitle =
              null;
        this.relatedWriters = this.relatedEditors = this.reviewers = [];
      }
    },
    fullName(item) {
      return `${item?.user?.firstName} ${item?.user?.lastName}`;
    },
    getWorkTypeRefs(index) {
      
      return this.isCue || index > 6
        ? this.workTypeReferences
        : this.workTypeReferences.filter((el) =>
            this[`iTunesWorkType${index}References`].includes(el.workTypeName)
          ).concat(this.requestType === "Localization" ? [this.workTypeReferences.find(el => el.workTypeName === "LOC_300-100-700")] : [""]);
    },
    handleAdditionalWorkType(index) {
      if (index === 1)
        this.workTypes.push({ customerPriceList: null, quantity: null, orderPosition: this.workTypes.length + 1 });
      else {
        let indexToRemove = this.workTypes.findIndex(obj => obj.orderPosition === index);
        if (indexToRemove !== -1) {
          this.workTypes.splice(indexToRemove, 1);
          for (let i = indexToRemove; i < this.workTypes.length; i++) {
            this.workTypes[i].orderPosition -= 1;
          }
        }
      }
    },
    handleDropdowns() {
      Promise.all([
        getEnum("Keyword"),
        getCustomerPriceList(),
        getEnum("FileLocation"),
      ])
        .then((resp) => {
          [this.keywords, this.workTypeReferences, this.fileLocations] = resp;
          this.dropdownsLoading = false;
        })
        .catch((err) => {
          console.error(err);
          this.dropdownsLoading = false;
        });
    },
    handlePayPeriod(dateInput) {
      if (!this.payPeriod) {
        this.payPeriod = dateInput.slice(0, -3);
      }
    },
    convertDeliveryDue() {
      if (
        !this.requestDate ||
        !this.clientDeliveryDate ||
        !this.clientDeliveryDue ||
        !this.timeZone
      ) {
        this.conversionErrors = true;
      } else {
        this.conversionErrors = false;
        let clientDeliveryData = new Date(
          `${this.clientDeliveryDate}T${this.$moment(
            this.clientDeliveryDue,
            "hh:mm A"
          ).format("HH:mm")}:00.000${this.timeZone.split(" ").at(-1)}`
        );
        let convertedDeliveryData = clientDeliveryData.toLocaleString("en-US", {
          timeZone: "America/New_York",
        });
        this.deliveryDate = this.$moment(convertedDeliveryData).format(
          "YYYY-MM-DD"
        );
        this.deliveryDue = clientDeliveryData.toLocaleString("en-US", {
          timeZone: "America/New_York",
          hour: "2-digit",
          minute: "2-digit",
        });
        if (this.urgent) {
          if (this.reviewer?.user?.id) {
            let convertedReviewerData = this.$moment(
              convertedDeliveryData
            ).subtract(3, "hours");
            this.reviewingDueDate = this.$moment(
              convertedReviewerData._d
            ).format("YYYY-MM-DD");
            this.reviewingDue = this.$moment(convertedReviewerData._d).format(
              "hh:mm A"
            );
            this.handleStaffAvailability("reviewers", "reviewing", "reviewer");
          }
          if (this.editor?.user?.id) {
            let convertedEditorData = this.$moment(
              convertedDeliveryData
            ).subtract(6, "hours");
            this.editingDueDate = this.$moment(convertedEditorData._d).format(
              "YYYY-MM-DD"
            );
            this.editingDue = this.$moment(convertedEditorData._d).format(
              "hh:mm A"
            );
            this.handleStaffAvailability("relatedEditors", "editing", "editor");
          }
          let convertedWriterData = this.$moment(
            convertedDeliveryData
          ).subtract(9, "hours");
          this.writingDueDate = this.$moment(convertedWriterData._d).format(
            "YYYY-MM-DD"
          );
          this.writingDue = this.$moment(convertedWriterData._d).format(
            "hh:mm A"
          );
        } else {
          if (this.reviewer?.user?.id || this.bulkEditing) {
            let convertedReviewerData = this.$moment(
              convertedDeliveryData
            ).subtract(6, "hours");
            this.reviewingDueDate = this.$moment(
              convertedReviewerData._d
            ).format("YYYY-MM-DD");
            this.reviewingDue = this.$moment(convertedReviewerData._d).format(
              "hh:mm A"
            );
            this.handleStaffAvailability("reviewers", "reviewing", "reviewer");
          }
          if (this.editor?.user?.id || this.bulkEditing) {
            let convertedEditorData = this.$moment(
              convertedDeliveryData
            ).subtract(18, "hours");
            this.editingDueDate = this.$moment(convertedEditorData._d).format(
              "YYYY-MM-DD"
            );
            this.editingDue = this.$moment(convertedEditorData._d).format(
              "hh:mm A"
            );
            this.handleStaffAvailability("relatedEditors", "editing", "editor");
          }
          let convertedWriterData = this.$moment(
            convertedDeliveryData
          ).subtract(24, "hours");
          this.writingDueDate = this.$moment(convertedWriterData._d).format(
            "YYYY-MM-DD"
          );
          this.writingDue = this.$moment(convertedWriterData._d).format(
            "hh:mm A"
          );
        }
        this.handleStaffAvailability("relatedWriters", "writing", "writer");
      }
    },
    getDatesRange(startDate, endDate) {
      let datesRange = [],
        dateVar = startDate;
      for (
        dateVar;
        this.$moment(dateVar).isSameOrBefore(this.$moment(endDate));
        dateVar = this.$moment(dateVar).add(1, "days").format("YYYY-MM-DD")
      ) {
        datesRange.push(this.$moment(dateVar).format("YYYY-MM-DD"));
      }
      return datesRange;
    },
    getAvailabilityData() {
      getEvents()
        .then((data) => {
          this.availabilityRecords = data.map((el) => ({
            name: `${el.personName?.firstName} ${el.personName?.lastName}`,
            dates: this.getDatesRange(
              this.$moment(el.startDate),
              this.$moment(el.endDate)
            ),
            writingAdjustedWorkload: el.adjustedDailyLocalization,
            editingAdjustedWorkload: el.adjustedDailyEditing,
          }));
        })
        .catch((err) => console.error(err));
    },
    handleStaffAvailability(staff, operation, specialist) {
      if (this[`${operation}DueDate`])
        this[staff].forEach((el) => {
          let unavailablePerson = this.availabilityRecords.find(
            (item) =>
              item.name === `${el.user.firstName} ${el.user.lastName}` &&
              item.dates.includes(this[`${operation}DueDate`])
          );
          unavailablePerson
            ? unavailablePerson.writingAdjustedWorkload == 0 &&
              unavailablePerson.editingAdjustedWorkload == 0 &&
              !this.overrideStaff
              ? (el.disabled = true)
              : (el.disabled = false)
            : (el.disabled = false);
        });
      if (specialist)
        if (this[specialist] && !this.overrideStaff) {
          if (
            this[staff].find((el) => el.user.id === this[specialist].user.id)
              ?.disabled
          ) {
            this[`${specialist}Unavailable`] = true;
          } else {
            this[`${specialist}Unavailable`] = false;
          }
        } else {
          this[`${specialist}Unavailable`] = false;
        }
    },
    resetSpecialistAvailabilityStatus(specialist) {
      this[`${specialist}Unavailable`] = false;
    },
    mergeRecordsByDate(initialArray, operation) {
      initialArray.forEach((el) => {
        const existingItems = this[`dataFor${operation}`].filter((item) => {
          return item[`${operation} Due Date`] === el[`${operation} Due Date`];
        });
        if (existingItems.length) {
          const existingItemIndex = this[`dataFor${operation}`].indexOf(
            existingItems[0]
          );
          this[`dataFor${operation}`][existingItemIndex]["Word Count"] =
            Number(
              this[`dataFor${operation}`][existingItemIndex]["Word Count"]
            ) + el["Word Count"];
        } else {
          this[`dataFor${operation}`].push(el);
        }
      });
    },
    getCapacity(capacityEmployee, operation) {
      // if (capacityEmployee?.user?.id) {
      //   this[`load${operation}DueDate`] = true;
      //   this[`default${operation}Capacity`] = {
      //     capacity:
      //       capacityEmployee.adminDetails[
      //         `defaultDaily${
      //           operation === "Writing" ? "Localization" : operation
      //         }`
      //       ],
      //   };
      //   getWorkloadByEmployee()
      //     .then((data) => {
      //       this.mergeRecordsByDate(data, operation);
      //       return getEvents({
      //         user: capacityEmployee,
      //       }).then((data) => {
      //         this[`adjusted${operation}Capacity`] = data.map((el) => ({
      //           dates: this.getDatesRange(
      //             this.$moment(el["Start Date"]),
      //             this.$moment(el["End Date"])
      //           ),
      //           capacity:
      //             el[
      //               `Adjusted Daily Workload - ${
      //                 operation === "Writing" ? "Localization" : operation
      //               }`
      //             ],
      //         }));
      //         this[`dataFor${operation}Capacity`] = this[
      //           `dataFor${operation}`
      //         ].map((el) => {
      //           const adjustedOperationCondition = this[
      //             `adjusted${operation}Capacity`
      //           ].find((acr) =>
      //             acr.dates.includes(el[`${operation} Due Date`])
      //           );
      //           const adjustedOperationRecord = this[
      //             `adjusted${operation}Capacity`
      //           ].find((acr) =>
      //             acr.dates.includes(el[`${operation} Due Date`])
      //           );
      //           return {
      //             operationCapacity: adjustedOperationCondition
      //               ? Number(adjustedOperationRecord.capacity) -
      //                 el["Word Count"]
      //               : Number(this[`default${operation}Capacity`].capacity) -
      //                 el["Word Count"],
      //             date: el[`${operation} Due Date`],
      //           };
      //         });
      //         this.handleCapacityCalculation(operation);
      //       });
      //     })
      //     .catch((err) => {
      //       console.error(err);
      //       this[`load${operation}DueDate`] = false;
      //     });
      // }
    },
    handleCapacityCalculation(operation) {
      // const capacityEmployee =
      //   operation === "Writing" ? this.writer?.user?.id : this.editor?.user?.id;
      // if (capacityEmployee) {
      //   if (this[`${operation.toLowerCase()}DueDate`] && this.wordCount) {
      //     this[`load${operation}DueDate`] = true;
      //     const actualCapacity = this[`dataFor${operation}Capacity`].find(
      //       (el) => el.date === this[`${operation.toLowerCase()}DueDate`]
      //     );
      //     const adjustedCapacity = this[`adjusted${operation}Capacity`].find(
      //       (el) => el.dates.includes(this[`${operation.toLowerCase()}DueDate`])
      //     );
      //     if (actualCapacity) {
      //       this[`errorFor${operation}Capacity`] =
      //         actualCapacity.operationCapacity - this.wordCount >= 0
      //           ? false
      //           : true;
      //       this[`load${operation}DueDate`] = false;
      //     } else if (adjustedCapacity) {
      //       adjustedCapacity.capacity > 0
      //         ? (this[`errorFor${operation}Capacity`] =
      //             adjustedCapacity.capacity - this.wordCount >= 0
      //               ? false
      //               : true)
      //         : (this[`errorFor${operation}Capacity`] = false);
      //       this[`load${operation}DueDate`] = false;
      //     } else {
      //       this[`errorFor${operation}Capacity`] =
      //         this[`default${operation}Capacity`].capacity - this.wordCount >= 0
      //           ? false
      //           : true;
      //       this[`load${operation}DueDate`] = false;
      //     }
      //   } else {
      //     this[`load${operation}DueDate`] = false;
      //   }
      // }
    },
    handleDueHoursAvailability(operation) {
      if (this[`${operation}DueDate`] === this.deliveryDate && !this.override) {
        return this.hoursList.map((el) => {
          if (
            this.hoursList.indexOf(el) >
            this.hoursList.findIndex((el) => el.time === this.deliveryDue)
          ) {
            return Object.assign({}, { time: el.time, disabled: true });
          } else {
            return Object.assign({}, { time: el.time, disabled: false });
          }
        });
      } else if (
        this.$moment(this[`${operation}DueDate`]).isAfter(this.deliveryDate) &&
        !this.override
      ) {
        return this.hoursList.map((el) => ({ time: el.time, disabled: true }));
      } else {
        return this.hoursList;
      }
    },
    handleDueHoursValidation(operation, updatedArray) {
      this[`${operation}DueHoursAvailable`] = !updatedArray.find(
        (el) => el.time === this[`${operation}Due`]
      )?.disabled;
    },
    handleStaffDuesValidation() {
      ["writing", "editing", "reviewing"].forEach((operation) => {
        if (!this.override) {
          if (this.deliveryDate && this[`${operation}DueDate`]) {
            this[`${operation}DueDateAvailable`] = this.$moment(
              this[`${operation}DueDate`]
            ).isSameOrBefore(this.deliveryDate);
            this.$refs[`${operation}DueDateField`].validate(true);
          }
        } else {
          this[`${operation}DueDateAvailable`] = true;
          this.$refs[`${operation}DueDateField`].validate(true);
        }
      });
    },
    handleWorkType1Quantity() {
      console.log(this.workTypes[0])
      if (
        this.requestType === "Localization" &&
        this.workTypes[1]?.customerPriceList?.workTypeName === "LOC_300-100-700"
      ) {
        this.workTypes[0].quantity = this.wordCount;
      }
    },
    getStaffByGeo(selectedGeo, itemToEditHandling) {
      if (this.bulkEditing) {
        this.relatedWriters = this.bulkEditingProject?.staff?.length
          ? this.bulkEditingProject.staff
              .filter((el) => el.adminDetails.geos.includes(selectedGeo))
              .map((el) => ({
                ...el,
                disabled: false,
              }))
              .sort((a, b) => a.user.firstName.localeCompare(b.user.firstName))
          : [];
      } else {
        this.relatedWriters = this.relatedProject.staff?.length
          ? this.relatedProject.staff
              .filter((el) => el.adminDetails.geos.includes(selectedGeo))
              .map((el) => ({
                ...el,
                disabled: false,
              }))
              .sort((a, b) => a.user.firstName.localeCompare(b.user.firstName))
          : [];
      }
      this.relatedEditors = [...this.relatedWriters];
      this.reviewers = [...this.relatedWriters];
      this.writer =
        this.relatedWriters.find(
          (el) =>
            el.user?.id ===
            (itemToEditHandling
              ? this.newAssignmentDatas[0].primary?.id
              : this.newAssignmentDatas[0].primary?.user?.id)
        ) ?? null;
      this.editor =
        this.relatedEditors.find(
          (el) =>
            el.user?.id ===
            (itemToEditHandling
              ? this.newAssignmentDatas[0].secondary?.id
              : this.newAssignmentDatas[0].secondary?.user?.id)
        ) ?? null;
      this.reviewer =
        this.relatedWriters.find(
          (el) =>
            el.user?.id ===
            (itemToEditHandling
              ? this.newAssignmentDatas[0].qc?.id
              : this.newAssignmentDatas[0].qc?.user?.id)
        ) ?? null;
      this.handleStaffAvailability("relatedWriters", "writing");
      this.handleStaffAvailability("relatedEditors", "editing");
      this.handleStaffAvailability("reviewers", "reviewing");
    },
    handleDueDate(flow, staff, specialist) {
      this.handleStaffAvailability(staff, flow, specialist);
      this[`${flow}DueDateAvailable`] = true;
    },
    handleDoc() {
      this.docLoading = true;
      getFile({ url: this.document }).then(async (string) => {
        this.document = ""; // indicates to backend the document don't needs to be updated
        const base64Response = await fetch(
          `data:application/pdf;base64,${string}`
        );
        const blob = await base64Response.blob();
        let newBlob = new Blob([blob], {
          type: "application/pdf",
        });
        newBlob.name = `${this.assignmentDetails}_${this.writer.user.firstName + this.writer.user.lastName}_${this.formatPickerDate(this.requestDate)}.pdf`;
        this.docBlob = newBlob;
        this.docLoading = false;
      });
    },
    convertDoc(file) {
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => (this.document = reader.result?.split(",")[1]);
        reader.readAsDataURL(file);
      } else {
        this.document = null;
      }
    },
    validateForm() {
      return this.$refs.form.validate();
    },
    handleAssignmentItemDeletion() {
      this.deleteNewAssignmentDatas(this.datasIndex);
    },
    handleBulkEditingMode() {
      if (this.bulkEditing) {
        this.updateField(null, "requestDate", "schedule");
        this.updateField(null, "assignmentStatus", "assignmentDetails");
        this.bulkEditingProject = this.projectsList.find(
          (el) => el.name === this.selectedDashboardRows[0].project?.name
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ad-new-assignment {
  border-bottom: 1px solid #e0e0e0;
}
::v-deep {
  .capacityNote.v-autocomplete,
  .capacityNote .v-label,
  .capacityNote .v-messages,
  .capacityNote .v-icon {
    color: #ff8f00 !important;
    caret-color: #ff8f00 !important;
  }
}

.anounce-text {
  margin: 0;
  position: absolute;
  top: 70px !important;
  left: 25px;
  font-size: 13px;
  color: rgb(30, 136, 229);
}
.anounce-text-container {
  position: relative;
}
.anounce-text-date {
  left: 45px !important;
  top: 60px !important;
}
.anounce-text-checkbox {
  margin: 0;
  position: absolute;
  top: 35px !important;
  left: 155px;
  font-size: 13px;
  color: rgb(30, 136, 229);
}
</style>
