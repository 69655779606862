var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-expansion-panel',{staticClass:"staff-expansion-panel grey lighten-4"},[((!_vm.xContent || _vm.cueLoc) && !_vm.shakespeareLoc)?_c('v-expansion-panel-header',{staticClass:"staff-expansion-panel__header"},[_c('span',[_vm._v(" "+_vm._s(_vm.groupItem)+" "),_c('v-chip',{staticClass:"identifier-count-chip white",attrs:{"small":"","label":""}},[_vm._v(" "+_vm._s(_vm.identifierCount)+" ")])],1)]):_vm._e(),_c('v-expansion-panel-content',{ref:"staffPanel",staticClass:"staff-expansion-panel__content",class:{ 'pt-5': (_vm.xContent && !_vm.cueLoc) || _vm.shakespeareLoc }},[(_vm.cueLoc)?_c('h3',{staticClass:"mb-4 text-center"},[_vm._v(" "+_vm._s(_vm.staffAssignments[_vm.groupItem].assignmentTitle)+" ")]):_vm._e(),_vm._l((_vm.panelGroup),function(panelItem){return _c('div',{key:panelItem,staticClass:"staff-expansion-panel__content__row",class:[
        _vm.shakespeare || _vm.backstageCopywriting || _vm.cueCopywriting || _vm.baseLocalization
          ? [
              'flex-wrap',
              'grey',
              'lighten-5',
              'rounded-lg',
              'elevation-1',
              'pt-6',
              'pb-2',
              'mb-4',
              'px-4',
            ]
          : '',
      ]},[(_vm.shakespeare || _vm.backstageCopywriting || _vm.cueCopywriting || _vm.baseLocalization)?_c('div',{staticClass:"pb-4 d-flex justify-center align-center"},[_vm._v(" "+_vm._s(_vm.baseLocalization && _vm.checkAdaptation(panelItem) ? `${panelItem} (Adaptation)` : panelItem)+" "),(_vm.shakespeare)?_c('v-btn',{staticClass:"white ml-4",attrs:{"x-small":""},on:{"click":function($event){return _vm.handleApplyToAll(panelItem)}}},[_vm._v("Apply to all [GEO]")]):_vm._e(),(_vm.shakespeare || _vm.backstageCopywriting || (_vm.baseLocalization && !_vm.checkAdaptation(panelItem)))?_c('v-btn',{staticClass:"white",class:_vm.backstageCopywriting ? 'ml-4' : 'ml-2',attrs:{"x-small":""},on:{"click":function($event){return _vm.handleApplyToAll(panelItem, true)}}},[_vm._v("Apply to all [Batch]")]):_vm._e(),(_vm.cueCopywriting)?_c('v-chip',{staticClass:"ml-4",attrs:{"color":_vm.getStatusData(panelItem).color}},[_vm._v(_vm._s(_vm.getStatusData(panelItem).name))]):_vm._e()],1):_vm._e(),_c('v-row',{staticClass:"align-center justify-center"},[(!_vm.shakespeare && !_vm.backstageCopywriting && !_vm.cueCopywriting && !_vm.baseLocalization)?_c('v-col',{staticClass:"text-center",attrs:{"cols":"16","sm":"1"}},[_vm._v(_vm._s(panelItem)+" ")]):_vm._e(),_c('v-col',{attrs:{"cols":!_vm.shakespeare && !_vm.backstageCopywriting && !_vm.cueCopywriting && !_vm.baseLocalization ? 16 : 12,"sm":!_vm.shakespeare && !_vm.backstageCopywriting && !_vm.cueCopywriting && !_vm.baseLocalization ? 3 : 4}},[_c('v-autocomplete',{attrs:{"label":"Primary","items":_vm.shakespeare || _vm.cueCopywriting
                ? _vm.staffDatasForGeo(_vm.groupItem, _vm.formValues.writingDueDate)
                : _vm.staffDatasForGeo(panelItem, _vm.formValues.writingDueDate),"value":_vm.getValue(
                panelItem,
                _vm.groupItem,
                'writer',
                _vm.formValues.writingDueDate
              ),"loading":_vm.staffLoading,"clearable":"","item-text":"name","rules":[_vm.rules.availabile('writer')]},on:{"change":(v) =>
                _vm.handleValueSelectChange(panelItem, 'writer', v, _vm.groupItem),"click:clear":function($event){return _vm.handleOverloadingReset(panelItem, 'writer', _vm.groupItem)}}})],1),_c('v-col',{attrs:{"cols":!_vm.shakespeare && !_vm.backstageCopywriting && !_vm.cueCopywriting && !_vm.baseLocalization ? 16 : 12,"sm":!_vm.shakespeare && !_vm.backstageCopywriting && !_vm.cueCopywriting && !_vm.baseLocalization ? 3 : 4}},[_c('v-autocomplete',{attrs:{"label":"Secondary","items":_vm.shakespeare || _vm.cueCopywriting
                ? _vm.staffDatasForGeo(_vm.groupItem, _vm.formValues.editingDueDate)
                : _vm.staffDatasForGeo(panelItem, _vm.formValues.editingDueDate),"value":_vm.getValue(
                panelItem,
                _vm.groupItem,
                'editor',
                _vm.formValues.editingDueDate
              ),"loading":_vm.staffLoading,"clearable":"","item-text":"name","rules":[_vm.rules.availabile('editor')]},on:{"change":(v) =>
                _vm.handleValueSelectChange(panelItem, 'editor', v, _vm.groupItem),"click:clear":function($event){return _vm.handleOverloadingReset(panelItem, 'editor', _vm.groupItem)}}})],1),_c('v-col',{attrs:{"cols":!_vm.shakespeare && !_vm.backstageCopywriting && !_vm.cueCopywriting && !_vm.baseLocalization ? 16 : 12,"sm":!_vm.shakespeare && !_vm.backstageCopywriting && !_vm.cueCopywriting && !_vm.baseLocalization ? 3 : 4}},[_c('v-autocomplete',{attrs:{"label":"QC","items":_vm.shakespeare || _vm.cueCopywriting
                ? _vm.staffDatasForGeo(_vm.groupItem, _vm.formValues.reviewingDueDate)
                : _vm.staffDatasForGeo(panelItem, _vm.formValues.reviewingDueDate),"value":_vm.getValue(
                panelItem,
                _vm.groupItem,
                'reviewer',
                _vm.formValues.reviewingDueDate
              ),"loading":_vm.staffLoading,"clearable":"","item-text":"name","rules":[_vm.rules.availabile('reviewer')]},on:{"change":(v) =>
                _vm.handleValueSelectChange(panelItem, 'reviewer', v, _vm.groupItem)}})],1),(
            !_vm.xContent &&
            !_vm.shakespeareLoc &&
            !_vm.shakespeare &&
            !_vm.backstageCopywriting &&
            !_vm.cueCopywriting
          )?_c('v-col',{staticClass:"text-center",attrs:{"cols":"16","sm":"2"}},[_c('v-btn',{staticClass:"white",attrs:{"x-small":""},on:{"click":function($event){return _vm.handleApplyToAll(panelItem)}}},[_vm._v("Apply to all [GEO]")])],1):_vm._e()],1),(_vm.shakespeare || _vm.backstageCopywriting)?_c('v-row',_vm._l(([1, 2, 3, 4, 5, 6]),function(i){return _c('v-col',{key:i,attrs:{"cols":"12","sm":"2"}},[_c('v-autocomplete',{staticClass:"py-2",attrs:{"label":`Work Type ${i} Reference`,"clearable":i !== 1,"items":_vm.getReferencesOptions(i, _vm.shakespeare ? _vm.groupItem : panelItem),"value":_vm.getValue(panelItem, _vm.groupItem, `workType${i}Reference`),"disabled":_vm.adaptationMode(_vm.shakespeare ? _vm.groupItem : panelItem)},on:{"change":(v) =>
                _vm.handleValueSelectChange(
                  panelItem,
                  `workType${i}Reference`,
                  v,
                  _vm.groupItem
                )}})],1)}),1):_vm._e(),((_vm.baseLocalization && _vm.applyLocMinRate === null && !_vm.checkAdaptation(panelItem)))?_c('v-row',{staticClass:"mt-0"},[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-autocomplete',{staticClass:"py-2",attrs:{"label":`Work Type 1 Reference`,"clearable":"","items":_vm.getLocReferencesOptions(1, _vm.shakespeare ? _vm.groupItem : panelItem),"value":_vm.getValue(panelItem, _vm.groupItem, `workType1Reference`),"disabled":_vm.adaptationMode(_vm.shakespeare ? _vm.groupItem : panelItem)},on:{"change":(v) =>
                _vm.handleValueSelectChange(
                  panelItem,
                  `workType1Reference`,
                  v,
                  _vm.groupItem
                )}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-autocomplete',{staticClass:"py-2",attrs:{"label":`Work Type 2 Reference`,"items":_vm.getLocReferencesOptions(2, _vm.shakespeare ? _vm.groupItem : panelItem),"value":'LOC_300-100-700'},on:{"change":(v) =>
                _vm.handleValueSelectChange(
                  panelItem,
                  `workType2Reference`,
                  'LOC_300-100-700',
                  _vm.groupItem
                )}})],1)],1):_vm._e(),((_vm.baseLocalization && _vm.applyLocMinRate === 'Combined (per word + per unit)' && !_vm.checkAdaptation(panelItem)))?_c('v-row',{staticClass:"row align-center justify-space-between mt-0"},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-row',{staticClass:"mt-0"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{staticClass:"py-1",attrs:{"label":`Work Type 1 Reference`,"clearable":"","items":_vm.getLocReferencesOptions(1, _vm.shakespeare ? _vm.groupItem : panelItem),"value":_vm.getValue(panelItem, _vm.groupItem, `workType1Reference`),"disabled":_vm.adaptationMode(_vm.shakespeare ? _vm.groupItem : panelItem)},on:{"change":(v) =>
                    _vm.handleValueSelectChange(
                      panelItem,
                      `workType1Reference`,
                      v,
                      _vm.groupItem
                    )}})],1)],1),_c('v-row',{staticClass:"mt-0"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"py-1",attrs:{"label":"Word Count","type":"number","rules":[_vm.rules.negativeCheck],"value":_vm.getValue(panelItem, _vm.groupItem, `workType1Quantity`)},on:{"change":(v) =>
                    _vm.handleValueSelectChange(
                      panelItem,
                      `workType1Quantity`,
                      v,
                      _vm.groupItem
                    )}})],1)],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-row',{staticClass:"mt-0"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{staticClass:"py-1",attrs:{"label":`Work Type 2 Reference`,"items":_vm.getLocReferencesOptions(2, _vm.shakespeare ? _vm.groupItem : panelItem),"value":'LOC_300-100-700'},on:{"change":(v) =>
                    _vm.handleValueSelectChange(
                      panelItem,
                      `workType2Reference`,
                      'LOC_300-100-700',
                      _vm.groupItem
                    )}})],1)],1),_c('v-row',{staticClass:"mt-0"},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{staticClass:"py-1",attrs:{"label":"Unit Count","type":"number","value":_vm.getValue(panelItem, _vm.groupItem, `workType2Quantity`),"rules":[_vm.rules.negativeCheck]},on:{"change":(v) =>
                    _vm.handleValueSelectChange(
                      panelItem,
                      `workType2Quantity`,
                      v,
                      _vm.groupItem
                    )}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{staticClass:"py-1",attrs:{"label":"Character Count","type":"number","value":_vm.getValue(panelItem, _vm.groupItem, `characterCount`),"rules":[_vm.rules.negativeCheck]},on:{"change":(v) =>
                    _vm.handleValueSelectChange(
                      panelItem,
                      `characterCount`,
                      v,
                      _vm.groupItem
                    )}})],1)],1)],1)],1):_vm._e(),((_vm.baseLocalization && _vm.applyLocMinRate && _vm.checkAdaptation(panelItem)))?_c('v-row',{staticClass:"row align-center justify-space-between mt-0"},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-autocomplete',{staticClass:"py-1",attrs:{"label":`Work Type 1 Reference`,"clearable":"","items":_vm.getLocReferencesOptions(3, _vm.shakespeare ? _vm.groupItem : panelItem),"value":_vm.getValue(panelItem, _vm.groupItem, `workType1Reference`),"disabled":true},on:{"change":(v) =>
                _vm.handleValueSelectChange(
                  panelItem,
                  `workType1Reference`,
                  v,
                  _vm.groupItem
                )}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{staticClass:"py-1",attrs:{"label":"Word Count","type":"number","rules":[_vm.rules.negativeCheck],"disabled":"","value":_vm.getValue(panelItem, _vm.groupItem, `workType1Quantity`)},on:{"change":(v) =>
                _vm.handleValueSelectChange(
                  panelItem,
                  `workType1Quantity`,
                  v,
                  _vm.groupItem
                )}})],1)],1):_vm._e(),((_vm.baseLocalization && _vm.applyLocMinRate === 'Per Word' && !_vm.checkAdaptation(panelItem)))?_c('v-row',{staticClass:"row align-center justify-space-between mt-0"},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-autocomplete',{staticClass:"py-1",attrs:{"label":`Work Type 1 Reference`,"clearable":"","items":_vm.getLocReferencesOptions(1, _vm.shakespeare ? _vm.groupItem : panelItem),"value":_vm.getValue(panelItem, _vm.groupItem, `workType1Reference`),"disabled":_vm.adaptationMode(_vm.shakespeare ? _vm.groupItem : panelItem)},on:{"change":(v) =>
                _vm.handleValueSelectChange(
                  panelItem,
                  `workType1Reference`,
                  v,
                  _vm.groupItem
                )}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{staticClass:"py-1",attrs:{"label":"Word Count","type":"number","rules":[_vm.rules.negativeCheck],"value":_vm.getValue(panelItem, _vm.groupItem, `workType1Quantity`)},on:{"change":(v) =>
                _vm.handleValueSelectChange(
                  panelItem,
                  `workType1Quantity`,
                  v,
                  _vm.groupItem
                )}})],1)],1):_vm._e(),((_vm.baseLocalization && _vm.applyLocMinRate === 'Per Unit' && !_vm.checkAdaptation(panelItem)))?_c('v-row',{staticClass:"row align-center justify-space-between mt-0"},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-autocomplete',{staticClass:"py-1",attrs:{"label":`Work Type 1 Reference`,"clearable":"","items":_vm.getLocReferencesOptions(1, _vm.shakespeare ? _vm.groupItem : panelItem),"value":_vm.getValue(panelItem, _vm.groupItem, `workType1Reference`),"disabled":_vm.adaptationMode(_vm.shakespeare ? _vm.groupItem : panelItem)},on:{"change":(v) =>
                _vm.handleValueSelectChange(
                  panelItem,
                  `workType1Reference`,
                  v,
                  _vm.groupItem
                )}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{staticClass:"py-1",attrs:{"label":"Unit Count","type":"number","value":_vm.getValue(panelItem, _vm.groupItem, `workType1Quantity`),"rules":[_vm.rules.negativeCheck]},on:{"change":(v) =>
                _vm.handleValueSelectChange(
                  panelItem,
                  `workType1Quantity`,
                  v,
                  _vm.groupItem
                )}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{staticClass:"py-1",attrs:{"label":"Character Count","type":"number","value":_vm.getValue(panelItem, _vm.groupItem, `characterCount`),"rules":[_vm.rules.negativeCheck]},on:{"change":(v) =>
                _vm.handleValueSelectChange(
                  panelItem,
                  `characterCount`,
                  v,
                  _vm.groupItem
                )}})],1)],1):_vm._e(),(_vm.shakespeare || _vm.backstageCopywriting)?_c('v-row',_vm._l(([1, 2, 3, 4, 5, 6]),function(i){return _c('v-col',{key:i,attrs:{"cols":"12","sm":"2"}},[_c('v-text-field',{staticClass:"py-2",attrs:{"label":`Work Type ${i} Quantity`,"type":"number","clearable":i !== 1,"value":_vm.getValue(panelItem, _vm.groupItem, `workType${i}Quantity`),"disabled":_vm.adaptationMode(_vm.shakespeare ? _vm.groupItem : panelItem),"rules":[_vm.rules.negativeCheck]},on:{"change":(v) =>
                _vm.handleValueSelectChange(
                  panelItem,
                  `workType${i}Quantity`,
                  v,
                  _vm.groupItem
                )}})],1)}),1):_vm._e(),(_vm.cueCopywriting)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-autocomplete',{staticClass:"py-2",attrs:{"label":"Work Type","items":_vm.allWorkTypes,"item-text":"workTypeName","loading":_vm.workTypesLoading,"value":_vm.getValue(panelItem, _vm.groupItem, 'workType1Reference')},on:{"change":(v) =>
                _vm.handleValueSelectChange(
                  panelItem,
                  'workType1Reference',
                  v,
                  _vm.groupItem
                )}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{staticClass:"py-2",attrs:{"label":"Quantity","type":"number","value":_vm.getValue(panelItem, _vm.groupItem, 'workType1Quantity'),"rules":[_vm.rules.negativeCheck]},on:{"change":(v) =>
                _vm.handleValueSelectChange(
                  panelItem,
                  'workType1Quantity',
                  v,
                  _vm.groupItem
                )}})],1)],1):_vm._e()],1)})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }