<template>
  <div class="led-dashboard my-10 px-2">
    <div class="d-flex justify-space-between" :style="{ position: 'relative' }">
      <!-- left -->
      <div class="d-flex">
        <!-- new record -->
        <div class="text-center">
          <span class="overline"> New </span>
          <div class="mb-4">
            <v-btn
              :min-width="laptop ? '40px' : '42px'"
              height="38px"
              class="elevation-2 px-0"
              color="secondary"
              :loading="dropdownsLoading"
              @click="handleForm({})"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </div>
        </div>

        <!-- form -->
        <v-dialog
          max-width="1200"
          :value="newLEDRecord"
          @click:outside="newLEDRecord = false"
          @keydown.esc="newLEDRecord = false"
        >
          <LEDForm
            :geoOptions="geos"
            :staffList="employees"
            :itemToEdit="itemToEdit"
            @led-dashboard-table-refresh="handleRefresh"
          />
        </v-dialog>

        <!-- report -->
        <div v-if="isAdmin || isManager" class="text-center ml-2">
          <span class="overline"> Report </span>
          <div>
            <v-btn
              height="38px"
              min-width="42px"
              class="elevation-2 px-0"
              color="secondary"
              :loading="showTableLoader || reportGenerating || isTableUpdating"
              @click="downloadReport"
            >
              <v-icon>mdi-tray-arrow-down</v-icon>
            </v-btn>
          </div>
        </div>

        <!-- clear filters -->
        <div class="text-center ml-2">
          <span class="overline"> Clear </span>
          <div class="mb-4">
            <v-btn
              :min-width="laptop ? '40px' : '42px'"
              height="38px"
              class="elevation-2 px-0"
              color="secondary"
              :loading="showTableLoader"
              :disabled="isTableUpdating"
              @click="clearFilter(false)"
            >
              <v-icon>mdi-filter-remove</v-icon>
            </v-btn>
          </div>
        </div>

        <!-- pay period filter -->
        <div class="ml-lg-3 ml-md-2">
          <span class="overline"> Pay Period </span>
          <div class="d-flex mb-4">
            <v-autocomplete
              class="led-dashboard__month-selector selector mr-2"
              :items="monthOptions"
              v-model="ppm"
              solo
              dense
              hide-details
              :disabled="isTableUpdating"
              @change="handleFilterQuery('ppm', $event)"
            ></v-autocomplete>
            <v-autocomplete
              class="led-dashboard__year-selector selector"
              :items="yearOptions"
              v-model="ppy"
              solo
              dense
              hide-details
              :disabled="isTableUpdating"
              @change="handleFilterQuery('ppy', $event)"
            >
            </v-autocomplete>
          </div>
        </div>

        <!-- type filter -->
        <div class="ml-lg-2 ml-md-1 text-center">
          <span class="overline"> Type </span>
          <div>
            <v-btn-toggle
              class="elevation-2"
              color="accent"
              multiple
              mandatory
              dense
              v-model="filterType"
              @change="handleFilterQuery('Type', $event)"
            >
              <v-btn height="38px" :disabled="isTableUpdating">Internal</v-btn>
              <v-btn height="38px" :disabled="isTableUpdating">External</v-btn>
            </v-btn-toggle>
          </div>
        </div>

        <!-- delivery date filter -->
        <div class="ml-lg-3 ml-md-2 text-center">
          <span class="overline"> Delivery Date </span>
          <div class="d-flex align-end mb-4">
            <v-menu
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              solo-inverted
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="formattedDeliveryDate"
                  :class="{ laptop }"
                  class="led-dashboard__date-selector selector"
                  solo
                  dense
                  readonly
                  hide-details
                  clearable
                  :disabled="isTableUpdating"
                  v-bind="attrs"
                  v-on="on"
                  @click:clear="clearFilter('DeliveryDate')"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="filterDeliveryDate"
                @input="handleFilterQuery('DeliveryDate', $event)"
              ></v-date-picker>
            </v-menu>
          </div>
        </div>

        <!-- GEO filter -->
        <div v-if="isAdmin || isManager" class="ml-lg-3 ml-md-2 text-center">
          <span class="overline"> GEO </span>
          <div class="mb-4">
            <v-autocomplete
              class="led-dashboard__geo-selector selector"
              :items="geos"
              v-model="filterGeo"
              solo
              dense
              hide-details
              :disabled="isTableUpdating"
              :loading="dropdownsLoading"
              @input="handleFilterQuery('Geo', $event)"
            ></v-autocomplete>
          </div>
        </div>

        <!-- person filter -->
        <transition>
          <div
            v-if="isAdmin || isManager"
            v-show="laptop ? !expandMenu : true"
            class="ml-lg-3 ml-md-2 text-center"
          >
            <span class="overline"> Person </span>
            <div class="d-flex mb-4">
              <v-autocomplete
                :class="{ laptop }"
                class="led-dashboard__person-selector selector mr-2"
                :items="employees"
                :item-text="fullName"
                return-object
                v-model="filterPerson"
                solo
                dense
                hide-details
                :disabled="isTableUpdating"
                :loading="dropdownsLoading"
                @input="handleFilterQuery('Person', $event)"
              ></v-autocomplete>
            </div>
          </div>
        </transition>
      </div>

      <!-- right -->
      <div class="d-flex flex-column justify-start align-end my-0">
        <!-- search -->
        <span class="overline" :style="{ opacity: 0, userSelect: 'none' }"
          >Search</span
        >
        <div class="mb-4">
          <v-badge
            :content="laptopSmaller ? '...' : filterSearchBadge"
            :value="filterSearch && !expandMenu"
            color="secondary"
            overlap
          >
            <v-btn
              height="38px"
              min-width="48px"
              class="elevation-2 px-0"
              outlined
              color="accent"
              @click="expandMenu = !expandMenu"
            >
              <v-icon v-if="expandMenu">mdi-magnify-minus-outline</v-icon>
              <v-icon v-else>mdi-magnify-plus-outline</v-icon>
            </v-btn>
          </v-badge>
          <v-expand-x-transition>
            <v-text-field
              :style="{ position: 'absolute', bottom: '16px', right: '60px' }"
              v-show="expandMenu"
              v-model="filterSearch"
              class="led-dashboard__search-selector selector"
              solo
              dense
              hide-details
              clearable
              :disabled="isTableUpdating"
              @click:clear="applySearch(true)"
              @keydown.enter="applySearch(false)"
            />
          </v-expand-x-transition>
        </div>
      </div>
    </div>
    <!-- table -->
    <v-card class="led-dashboard__table-card">
      <v-skeleton-loader
        v-if="showTableLoader"
        type="table"
        height="100vh"
      ></v-skeleton-loader>
      <LEDTable
        ref="ledTable"
        v-if="tableData && !showTableLoader"
        :totalHours="totalHours"
        :tableData="tableData"
        :serverItemsLength="serverItemsLength"
        :isTableUpdating="isTableUpdating"
        :filterGeo="filterGeo ? filterGeo : ''"
        :filterDeliveryDate="filterDeliveryDate ? filterDeliveryDate : ''"
        @call-edit-form="handleForm"
        @refresh-request="refreshTableData"
      />
    </v-card>
  </div>
</template>

<script>
// vuex
import { mapGetters, mapActions } from "vuex";
// internal
import {
  getAssignments,
  getAllStaff,
  getEnum,
  getClientRequesters,
} from "@/utils/newDbUtils";
import { customBreakpoint, timeUnits } from "@/utils/mixins";
import { ROUTES } from "@/utils/constants";
import { ledReport } from "@/utils/ledDashboardUtils";
// components
import LEDTable from "@/components/LEDDashboard/LEDTable";
import LEDForm from "@/components/LEDDashboard/LEDForm";

export default {
  name: "LEDDashboard",
  components: {
    LEDTable,
    LEDForm,
  },
  data() {
    return {
      showTableLoader: true,
      tableData: null,
      totalHours: null,
      serverItemsLength: 0,
      ppm: null,
      ppy: null,
      filterDeliveryDate: null,
      clientRequesters: [],
      filterType: [0, 1],
      filterSearch: null,
      expandMenu: false,
      dropdownsLoading: true,
      employees: [],
      filterPerson: null,
      geos: [],
      filterGeo: null,
      isTableUpdating: false,
      newLEDRecord: false,
      itemToEdit: {},
      itemsPerPage: 50,
      page: 0,
      sortBy: "schedule_deliveryDate",
      sorting: "desc",
      sort: {
        sortByDeliveryDate: false,
        sortByRequestDate: null,
        sortByAssignmentTitle: null,
        sortByAssignmentDetail: null,
        sortByMediaType: null,
        sortByWordCount: null,
        sortByWorkTypeQuantity: null,
        sortByGeo: null,
      },
      reportGenerating: false,
    };
  },
  computed: {
    ...mapGetters("auth", ["user", "isAdmin", "isManager"]),
    filterSearchBadge() {
      return this.filterSearch?.length <= 6
        ? this.filterSearch
        : `${this.filterSearch?.slice(0, 6)}...`;
    },
    formattedDeliveryDate: {
      get() {
        return this.formatPickerDate(this.filterDeliveryDate);
      },
      set() {
        this.filterDeliveryDate = null;
      },
    },
    typeQuery() {
      const external = [
        this.clientRequesters.find(
          (el) =>
            el.user.firstName === "Pilar" &&
            el.user.lastName === "Blanco" &&
            el.projects?.some((proj) => proj.name === "iTunes FC")
        )?.user?.id,
      ];
      const internal = this.clientRequesters
        .filter(
          (el) =>
            (el.user.firstName === "Ines" || el.user.firstName === "Company") &&
            (el.user.lastName === "Santos" || el.user.lastName === "Cue, Inc. (FC)")
        )
        .map((el) => el?.user?.id);
      return this.filterType.length > 1
        ? external.concat(internal).join("_,_")
        : this.filterType.includes(0)
        ? internal.join("_,_")
        : external[0];
    },
    query() {
      return {
        page: this.page,
        size: this.itemsPerPage,
        sortByDeliveryDate: this.sort.sortByDeliveryDate,
        sortByRequestDate: this.sort.sortByRequestDate,
        sortByAssignmentTitle: this.sort.sortByAssignmentTitle,
        sortByAssignmentDetail: this.sort.sortByAssignmentDetail,
        sortByMediaType: this.sort.sortByMediaType,
        sortByWordCount: this.sort.sortByWordCount,
        sortByWorkTypeQuantity: this.sort.sortByWorkTypeQuantity,
        sortByGeo: this.sort.sortByGeo,
        search: this.filterSearch,
        deliveryDate: this.filterDeliveryDate,
        ppy: this.ppy,
        ppm: this.ppm?.toUpperCase(),
        geo: this.filterGeo,
        personId: this.filterPerson?.user?.id,
        clientRequesterId: this.typeQuery,
        requestType: "Editorial Supervision",
      };
    },
  },
  mixins: [customBreakpoint, timeUnits],
  watch: {
    filterSearch(newValue) {
      const currentQuery = this.$route.query;
      const newQuery =
        newValue !== ""
          ? {
              ...currentQuery,
              Search: newValue,
            }
          : _.omit(currentQuery, ["Search"]);

      this.$router
        .push({ path: `/${ROUTES.ledDashboard}`, query: newQuery })
        .catch(() => {}); // Avoided redundant navigation error handler
    },
  },
  mounted() {
    this.handleQuery();
    this.handleDropdowns();
  },
  methods: {
    ...mapActions("flashMessage", ["handleFlash"]),
    fullName(item) {
      return `${item.user.firstName} ${item.user.lastName}`;
    },
    applySearch(reset) {
      if (reset) this.filterSearch = null;
      this.handleFilterQuery("Search", this.filterSearch);
    },
    getTableData(hardReload) {
      if (hardReload) this.showTableLoader = true;
      else this.isTableUpdating = true;
      getAssignments({
        query: this.query,
        ledId: this.isAdmin || this.isManager ? null : this.user.user.id,
      }).then((resp) => {
        if (resp?.status > 204) {
          this.tableData = [];
          this.handleFlash({ response: resp, show: true });
        } else {
          this.tableData = resp.assignments.content.map((el) => ({
            ...el,
            groupWeek: this.getWeekDates(el.schedule.deliveryDate).join(", "),
          }));
          this.totalHours = resp.hours;
        }
        this.serverItemsLength = resp?.assignments.totalElements ?? 0;
        this.showTableLoader = this.isTableUpdating = false;
      });
    },
    refreshTableData(options) {
      const sortKey = options.sortBy[0];
      const sortDir = options.sortDesc[0];
      const handleSort = (key) => {
        const capitalizeKey = () => {
          const extractedKey = key.split("[")[1].slice(0, -1);
          return extractedKey.charAt(0).toUpperCase() + extractedKey.slice(1);
        };
        const handleDir = () => {
          if (sortDir === undefined || sortDir)
            return key !== "schedule[deliveryDate]";
          else return key === "schedule[deliveryDate]";
        };
        Object.keys(this.sort).forEach((el) => {
          this.sort[el] =
            el === `sortBy${capitalizeKey()}` ? handleDir() : null;
        });
      };
      this.itemsPerPage = options.itemsPerPage;
      this.page = options.page - 1;
      handleSort(sortKey ?? "schedule[deliveryDate]");
      this.getTableData();
    },
    handleFilterQuery(param, data) {
      const query = {
        ...this.$route.query,
        [param]:
          param === "Person" && data
            ? `${data.user.firstName} ${data.user.lastName}`
            : data,
      };
      this.$router
        .push({ path: `/${ROUTES.ledDashboard}`, query })
        .catch(() => {}); // Avoided redundant navigation error handler

      this[param === "ppy" || param === "ppm" ? param : `filter${param}`] =
        data;

      if (param === "ppm" && !this.ppy) return;

      if (this.$refs.ledTable?.options?.page === 1) this.getTableData();
      else this.$refs.ledTable.options.page = 1;
    },
    clearFilter(date) {
      let query = this.$route.query;

      if (date) {
        query = _.omit(query, [date]);
        this[`filter${date}`] = null;
      } else {
        this.filterType = [0, 1];
        this.filterPerson =
          this.filterGeo =
          this.filterSearch =
          this.ppm =
          this.ppy =
            null;
        query = {};
      }

      if (!date) this.filterDeliveryDate = null;

      this.$router
        .push({ path: `/${ROUTES.ledDashboard}`, query })
        .catch(() => {}); // Avoided redundant navigation error handler

      if (this.$refs.ledTable?.options?.page === 1) this.getTableData();
      else this.$refs.ledTable.options.page = 1;
    },
    getEmployees() {
      return new Promise((resolve, reject) => {
        this.isAdmin || this.isManager
          ? getAllStaff()
              .then((datas) => resolve((this.employees = datas)))
              .catch((err) => reject(console.error(err)))
          : resolve(null);
      });
    },
    getGeos() {
      return new Promise((resolve, reject) => {
        getEnum("GEO")
          .then((geos) => {
            resolve((this.geos = geos));
          })
          .catch((err) => {
            reject(console.error(err));
          });
      });
    },
    handleDropdowns() {
      Promise.all([this.getEmployees(), this.getGeos()])
        .then(() => {
          this.dropdownsLoading = false;
        })
        .catch((err) => {
          this.dropdownsLoading = false;
          console.error(err);
        });
    },
    handleQuery() {
      let query = { ...this.$route.query };

      if (_.isEmpty(query)) {
        this.ppm = this.$moment().format("MMMM");
        this.ppy = this.$moment().format("YYYY");
        query = {
          ppm: this.ppm,
          ppy: this.ppy,
        };
      } else {
        Object.keys(query).forEach((param) => {
          if (param === "Type") {
            this[`filter${param}`] =
              typeof query[param] === "string"
                ? [Number(query[param])]
                : query[param].map((x) => Number(x));
          } else {
            this[
              param === "ppy" || param === "ppm" ? param : `filter${param}`
            ] = query[param];
          }
        });
      }

      this.$router
        .push({ path: `/${ROUTES.ledDashboard}`, query })
        .catch(() => {}); // Avoided redundant navigation error handler

      this.handleInitialDataRequest();
    },
    handleInitialDataRequest: async function () {
      this.clientRequesters = await getClientRequesters();
      this.getTableData(true);
    },
    handleForm(tableRow) {
      this.itemToEdit = { ...tableRow };
      this.newLEDRecord = true;
    },
    handleRefresh(resp) {
      this.getTableData(true);
      this.newLEDRecord = false;
      this.handleFlash({ response: resp, show: true });
    },
    getWeekDates(date) {
      let weekDates = [];
      for (let i = 1; i <= 7; i++) {
        weekDates.push(
          this.$moment(this.$moment(date).day(i)._d).format("YYYY-MM-DD")
        );
      }
      return weekDates;
    },
    downloadReport() {
      this.reportGenerating = true;
      getAssignments({
        query: { ...this.query, size: this.serverItemsLength },
        ledId: null,
      }).then((resp) => {
        if (resp?.status > 204)
          this.handleFlash({ response: resp, show: true });
        else {
          const reportData = resp.assignments.content.reduce((acc, value) => {
            if (
              value.clientRequester?.firstName === "Pilar" &&
              value.clientRequester?.lastName === "Blanco"
            ) {
              return [
                ...acc,
                {
                  ...value,
                  groupWeek: this.getWeekDates(
                    value.schedule.deliveryDate
                  ).join(", "),
                },
              ];
            } else {
              return acc;
            }
          }, []);
          ledReport(reportData);
        }
        this.reportGenerating = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.led-dashboard {
  max-width: 1500px;
  margin: 0 auto;

  &__month-selector {
    width: 120px;
  }

  &__year-selector {
    width: 80px;
  }

  &__date-selector {
    width: 130px;
    &.laptop {
      width: 120px;
    }
  }

  &__person-selector {
    width: 170px;
    &.laptop {
      width: 160px;
    }
  }

  &__geo-selector {
    width: 100px;
  }

  &__search-selector {
    width: 340px;
  }
}

::v-deep {
  .selector.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
    > .v-input__control
    > .v-input__slot {
    padding-right: 0;
  }
  .selector.v-text-field.v-text-field--solo .v-input__append-inner {
    padding-left: 0;
  }
  .selector.v-autocomplete.v-select.v-input--is-focused input {
    min-width: 0;
  }
  .selector.v-text-field.v-input--dense:not(.v-text-field--outlined) input {
    padding: 2px 0;
  }
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}
.v-enter,
.v-leave-to {
  opacity: 0;
}

@import "~vuetify/src/styles/settings/_variables";

@media #{map-get($display-breakpoints, 'lg-and-down')} {
  .v-application .overline {
    font-size: 0.6rem !important;
  }
}
</style>
