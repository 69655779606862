<template>
  <div class="spreadsheet-upload">
    <v-card
      class="spreadsheet-upload__source-section pa-5 mt-5"
      :class="{ active: sourceFile }"
    >
      <h2>Client Files</h2>
      <SourceInput />
      <v-scale-transition
        ><SourceDropzone
          v-if="
            sourceFile &&
            !xContent &&
            !shakespeare &&
            !shakespeareLoc &&
            !cueCopywriting
          "
      /></v-scale-transition>
    </v-card>
    <v-scale-transition>
      <v-card v-if="sourceFile && !dubEditor" class="pa-5 pt-2 mt-2">
        <SourceTable />
      </v-card>
    </v-scale-transition>
    <v-scale-transition
      ><v-card class="pa-5 mt-5" v-if="sourceFile"> <InfoForm /> </v-card
    ></v-scale-transition>
  </div>
</template>

<script>
// vuex
import { mapGetters, mapActions } from "vuex";
// libraries
import _ from "lodash";
// components
import { getAllProjects, getAllStaff } from "@/utils/newDbUtils";
import SourceDropzone from "@/components/SpreadsheetUpload/SourceDropzone";
import SourceInput from "@/components/SpreadsheetUpload/SourceInput";
import SourceTable from "@/components/SpreadsheetUpload/SourceTable";
import InfoForm from "@/components/SpreadsheetUpload/InfoForm";

export default {
  components: { SourceDropzone, SourceInput, SourceTable, InfoForm },
  watch: {
    files(newValue) {
      // check for and set if files to process match source file
      const filenames = newValue.map((el) => el.name.split(".xlsx")[0]);
      const sourceNames = this.tableRows.map((el) => el.task_name);

      this.setAllSourceFilesFound(
        _.difference(filenames, sourceNames).length === 0 &&
          filenames.length > 0 &&
          sourceNames.length > 0
      );
    },
    tableRows(newValue) {
      const filenames = this.files.map((el) => el.name.split(".xlsx")[0]);
      const sourceNames = newValue?.map((el) => el.task_name);
      this.setAllSourceFilesFound(
        _.difference(filenames, sourceNames).length === 0 &&
          filenames.length > 0 &&
          sourceNames.length > 0
      );
    },
  },
  methods: {
    ...mapActions("assignment", ["setCommonData"]),
    ...mapActions("spreadsheetUpload", ["setAllSourceFilesFound", "setstaffListData", "setDubEditor"]),
    async getProjects() {
      await getAllProjects()
        .then((data) => {
          this.setCommonData({ field: "projectsList", data: data });
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
  computed: {
    ...mapGetters("spreadsheetUpload", [
      "files",
      "tableRows",
      "allSourceFilesFound",
      "sourceFile",
      "xContent",
      "shakespeare",
      "shakespeareLoc",
      "cueCopywriting",
      "dubEditor",
      "staffListData"
    ]),
    fileNames() {
      return this.files.map((f) => f.name);
    },
  },
  async mounted() {
    this.getProjects();
    if (!this.staffListData?.length) {
      let staffList = await getAllStaff();
      this.setstaffListData(staffList);
      this.staffList = _.cloneDeep(this.staffListData);
    } else {
      this.staffList = _.cloneDeep(this.staffListData);
    }
  },
  beforeRouteLeave(to, from, next) {
    this.setDubEditor(false);
    next();
  }
};
</script>

<style lang="scss" scoped>
.spreadsheet-upload {
  max-width: 1200px;
  margin: 0 auto;

  &__source-section {
    max-height: 160px;
    transition: max-height 0.15s ease-out;
    &.active {
      max-height: 1000px;
      transition: max-height 0.25s ease-in;
    }
  }
}
</style>
